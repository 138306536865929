
import { formatThousands } from '../utils/Utils';
import { formatDate } from '../utils/Utils';


function TableLastOrderItem({ order }) {
  



    const totalIva = (order.quantity)*(order.unitPrice) * (order.commision/100) * (0.21) + (order.quantity)*(order.unitPrice)


    return (<tbody className="text-sm" key={"order" + order.id}>
        <tr>

            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center text-airco2-general">
                <div>{order.customerName}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center text-airco2-general">
                <div>{formatDate(order.creationDate)}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center text-airco2-general">
                <div>  {formatThousands(order.quantity)} t</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center text-airco2-general">
                <div> {totalIva !== null && formatThousands(totalIva.toFixed(2))} €</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center text-airco2-general">
                <div> {order.total !== null && formatThousands(order.total.toFixed(2)) + "€"}  </div>
            </td>





        </tr>


    </tbody>)
}
export default TableLastOrderItem;
