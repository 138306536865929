import { createContext, useCallback, useContext, useMemo, useState } from "react";
import PropTypes from 'prop-types';

export const ShoppingContext = createContext();

export function ShoppingContextProvider({ children }) {
    const [cart, setCart] = useState({});
    const [updateCartState, setUpdateCartState] = useState(false);
    const [isInicialize, setInicialize] = useState(false);

    const updateCart = useCallback(function (data) {
        setCart(data);
        setUpdateCartState(true)
    }, []);

    const inicialize = useCallback(function (data) {
        setInicialize(true)
        setCart(data);
    }, []);

    const value = useMemo(() => ({
        updateCart,
        setUpdateCartState,
        inicialize,
        isInicialize,
        cart,
        updateCartState
    }), [updateCart, setUpdateCartState, inicialize, isInicialize, cart, updateCartState]);


    return <ShoppingContext.Provider value={value}>
        {children}
    </ShoppingContext.Provider>

}

ShoppingContextProvider.propTypes = {
    children: PropTypes.object
}

export function useShoppingContext() {
    return useContext(ShoppingContext);
}