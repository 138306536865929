
import { useTranslation } from 'react-i18next'
import Logo from './../images/logo2.png'

export default function Loading() {
    const { t } = useTranslation();
    return <div className="grid h-screen place-items-center bg-airco2-principal">
        <div className="text-white ">
            <img src={Logo} alt="logo" className='mx-auto ' />

            <div className="text-center text-2xl mb-5 mt-3">
                {t("loading.bienvenidos")}
            </div>

            <div className="flex justify-center items-center">
                <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
}