import { createContext, useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import PropTypes from 'prop-types';

export const CategoriesContext = createContext();
export function CategoriesContextProvider({ children }) {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_MP_CATEGORIES}`, {

                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                });
                setCategories(response.data);

            } catch (exception) {
                setCategories(categories);
            }
        };
        fetchCategories();
    }, []);


    const value = useMemo(() => ({
        categories,
    }), [categories]);


    return <CategoriesContext.Provider value={value}>
        {children}
    </CategoriesContext.Provider>

}

CategoriesContextProvider.propTypes = {
    children: PropTypes.object
}

export function useCategoriesContext() {
    return useContext(CategoriesContext);
}