import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { handleClick } from "./Utils";



export default function HomeBanner() {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);

    const handleNext = () => {
        const nextIndex = activeIndex === 2 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const handlePrev = () => {
        const prevIndex = activeIndex === 0 ? 2 : activeIndex - 1;
        setActiveIndex(prevIndex);
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNext();
        }, 5000);
        return () => clearInterval(intervalId);
    });



    return (
        <div id="carouselExampleControls" className="carousel slide relative" data-bs-ride="carousel">
            <div className="carousel-inner relative w-full overflow-hidden">
                {/* PRIMER SLIDER MARKETPLACE */}
                <div className={`carousel-item pic-carousel1 relative float-left w-full ${activeIndex === 0 ? 'active' : ''}`}>

                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6 h-full">
                        <div className="flex h-full justify-center	items-center	 ">
                            <div className="max-w-3xl mx-auto text-center mt-20 md:mt-10">
                                <h2 className="text-airco2-home-titleSlider mb-4" data-aos="fade-down" >{t("home.marketplaceUnico")}
                                    </h2>
                                <h3 className="text-airco2-home-subtitleSlider mb-4" data-aos="fade-down">{t("home.valorNetZero")}</h3>

                            </div>
                        </div>
                    </div>
                </div>
                {/* SEGUNDO SLIDER MAPA */}
                <div className={`carousel-item pic-carousel2 relative float-left w-full ${activeIndex === 1 ? 'active' : ''}`}>
                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6 h-full">
                        <div className="flex h-full justify-center	items-center	 ">
                            <div className="max-w-3xl mx-auto text-center mt-20 md:mt-10">
                                <h2 className=" text-airco2-home-titleSlider mb-4" data-aos="fade-down" >{t("home.+30Arboles")}</h2>

                                <div className="mt-8" data-aos="fade-down" data-aos-delay="300">
                                    <Link onClick={handleClick} className="" to="/map">
                                        <button className='bg-airco2-principal w-60 pt-1 pb-1 '>
                                        {t("home.btnMapa")}
                                        </button>

                                      </Link>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className={`carousel-item pic-carousel3 float-left w-full ${activeIndex === 2 ? 'active' : ''}`}>

                    <div className="relative max-w-6xl mx-auto px-4 sm:px-6 h-full">
                        <div className="flex h-full justify-center	items-center	 ">
                            <div className="max-w-3xl mx-auto text-center mt-20 md:mt-10">
                                <h2 className=" text-airco2-home-titleSlider mb-4" data-aos="fade-down" >{t("home.bosquePersonas")}</h2>
                              
                                <div className="mt-8" data-aos="fade-down" data-aos-delay="300">
                                <Link onClick={handleClick} className=""  to="/catalogo">
                                        <button className='bg-airco2-principal w-60 pt-1 pb-1 '>
                                        {t("home.btnCatalogo")}
                                        </button>

                                      </Link>
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
                onClick={handlePrev}
            >
                <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
                onClick={handleNext}
            >
                <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}
