import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



const DatePickerComponent = ({ startDate, endDate, handleStartDateChange, handleEndDateChange }) => {
  


  

    return (
        <div className="date-picker-container">
            <div className="date-picker ">
                <label className='text-sm'>Fecha de inicio:</label>
                <DatePicker
                    className='date-picker-box'
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="date-picker">
                <label className='text-sm'>Fecha de fin:</label>
                <DatePicker
                    className='date-picker-box'
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
         
        </div>
    );
};

export default DatePickerComponent;

