import axios from 'axios';
import moment from "moment";
import React, { useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePickerComponent from '../components/Datepicker';
import FilterButton from '../components/FilterButton';
import { formatDate, formatThousands } from '../utils/Utils';
import TemplatePage from './TemplatePage';




export default function ForestList() {
    const { t } = useTranslation();



    const [forest, setForest] = useState({
        data: [],
        isFetching: false
    });

    const [forestFilter, setForestFilter] = useState();



    const fetchForest = async () => {
        try {
            setForest({ data: forest.data, isFetching: true });
            const response = await axios.get(`${process.env.REACT_APP_PUBLIC_FOREST}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            });
            setForest({ data: response.data, isFetching: false });
            setForestFilter(response.data.data)


            setForestFilter(response.data);

        } catch (exception) {
            setForest({ data: [], isFetching: false });
        }
    };




    useEffect(() => {

        fetchForest();
    }, [])




    const useSortableData = (items, config = null) => {

        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            if (!items || items.length === 0) {
                return [];
            }
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    let valueA = a[sortConfig.key];
                    let valueB = b[sortConfig.key];

                    if (sortConfig.key.includes(".")) {
                        let params = sortConfig.key.split(".");
                        valueA = a[params[0]]?.[params[1]];
                        valueB = b[params[0]]?.[params[1]];
                    }

                    if (valueA === null || valueA === undefined || valueA === "") {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }

                    if (valueB === null || valueB === undefined || valueB === "") {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }

                    if (!isNaN(valueA) || moment(valueA).isValid()) {
                        if (valueA < valueB) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (valueA > valueB) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    } else {
                        if (
                            valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? -1 : 1;
                        }
                        if (
                            valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
                        ) {
                            return sortConfig.direction === "ascending" ? 1 : -1;
                        }
                    }

                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(forestFilter);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };



    const [filtros, setFiltros] = useState({
        cadastre: "",
        forestType: [],
        province: [],
        state: [],
        startDate: null,
        endDate: null,

    });


    const applyCategoryFilter = (data, filterType) => {
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            [filterType]: data,
        }));
    };



    // FILTRO TIPO ESPECIE
    const [uniqueForestTypeList, setUniqueForestypeList] = useState(
        []
    );


    const [forestTypeList, setForestTypeList] = useState([]);
    useEffect(() => {
        const uniqueForestType = Array.from(new Set(forest?.data?.flatMap(forest => forest.forestType)));
        const uniqueForestTypeSplit = uniqueForestType.flatMap(type => type.split(', ')).filter((value, index, self) => self.indexOf(value) === index);;
        setUniqueForestypeList(uniqueForestTypeSplit.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())));


        setForestTypeList(uniqueForestTypeSplit.map(forestType => ({
            category: forestType,
            check: false
        })));




    }, [forest?.data]);


    const OnFilterForestType = (data, filteredData) => {
        const selectedForestType = data.filter((forest) => forest.check).map((forest) => forest.category.toString());


        if (selectedForestType.length > 0) {
            const filteredResult = filteredData.filter((forest) => {
                const forestTypes = Array.isArray(forest.forestType) ? forest.forestType : [forest.forestType];



                return selectedForestType.some(selectedType =>
                    forestTypes.some(type => type.includes(selectedType))
                );
            });

            return filteredResult;
        }

        return filteredData;
    };




    // FILTRO PROVINCIA
    const [uniqueProvinceList, setUniqueProvinceList] = useState(
        []
    );

    function capitalizeFirstLetter(string) {
        return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }

    const [provinceList, setProvinceList] = useState([]);
    useEffect(() => {
        const uniqueProvinceList = Array.from(new Set(forest?.data?.flatMap(forest => forest.province)));
        setUniqueProvinceList(uniqueProvinceList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())));


        setProvinceList(uniqueProvinceList.map(province => ({
            category: capitalizeFirstLetter(province),
            check: false
        })));






    }, [forest?.data]);

    const OnFilterProvince = (data, filteredData) => {


        const selectedProvince = data.filter((forest) => forest.check).map((forest) => forest.category.toString());



        if (selectedProvince.length > 0) {

            const filteredResult = filteredData.filter((forest) => {

                const provinces = Array.isArray(forest.province) ? capitalizeFirstLetter(forest.province) : [capitalizeFirstLetter(forest.province)];


                return provinces.some(type => selectedProvince.includes(type.toString()));
            });

            return filteredResult;

        }

        return filteredData;
    };


    // FILTRO ESTADO

    const [uniqueStateList, setUniqueStateList] = useState(
        []
    );



    const [stateList, setStateList] = useState([]);
    useEffect(() => {
        const uniqueStateList = Array.from(new Set(forest?.data?.flatMap(forest => forest.state)));
        setUniqueStateList(uniqueStateList);


        setStateList(uniqueStateList.map(state => ({
            category: state,
            check: false
        })));




    }, [forest?.data]);

    const OnFilterState = (data, filteredData) => {

        const selectedstate = data.filter((forest) => forest.check).map((forest) => forest.category.toString());



        if (selectedstate.length > 0) {

            const filteredResult = filteredData.filter((forest) => {
                const states = Array.isArray(forest.state) ? forest.state : [forest.state];

                return states.some(type => selectedstate.includes(type.toString()));
            });

            return filteredResult;

        }

        return filteredData;
    };




    //FILTRO CATASTRO 

    const OnFilterCadastre = (filter, filteredData) => {
        return filteredData.filter((v) =>
            v.cadastre.toLowerCase().includes(filter.toLowerCase())
        );

    };


    //FILTRO FECHA


    const handleStartDateChange = (date) => {

        setFiltros(prevFiltros => ({
            ...prevFiltros,
            startDate: date
        }));
    };

    const handleEndDateChange = (date) => {
        setFiltros(prevFiltros => ({
            ...prevFiltros,
            endDate: date
        }));
    };


    const handleDeleteDate = () => {
        setFiltros(prevFiltros => ({
            ...prevFiltros,
            startDate: null,
            endDate: null,

        }));
    };

    const OnFilterActivationDate = (startDate, endDate, filteredData) => {
        if (!startDate || !endDate) {
            return filteredData;
        }

        return filteredData.filter((item) => {
            const activationDate = new Date(item.activationDate ? item.activationDate : item.validationDate);

            return activationDate >= startDate && activationDate <= endDate;

        });
    };





    useEffect(() => {
        const handleInputChange = () => {

            let filteredData = forest?.data


            if (filtros.cadastre) {
                filteredData = OnFilterCadastre(filtros.cadastre, filteredData);
            }


            if (filtros.forestType) {
                filteredData = OnFilterForestType(filtros.forestType, filteredData);
            }


            if (filtros.province) {

                filteredData = OnFilterProvince(filtros.province, filteredData);
            }


            if (filtros.state) {

                filteredData = OnFilterState(filtros.state, filteredData);
            }
            if (filtros.startDate && filtros.endDate) {
                filteredData = OnFilterActivationDate(filtros.startDate, filtros.endDate, filteredData);

            }


            setForestFilter(filteredData);
        };
        handleInputChange();
    }, [filtros]);



    function getStatusInfo(status) {
        let info = {
            text: '',
            color: ''
        };

        switch (status) {
            case 'ACTIVE':
                info.text = 'En venta';
                info.color = '#C4F1B3';
                break;
            case 'VALIDATEDANDCERTIFICATED':
                info.text = 'Firmado';
                info.color = '#FFFFCC';
                break;
            default:
                info.text = 'N/D';
                info.color = '#F19B7F';
                break;
        }

        return info;
    }

    const [width, setWidth] = useState(window.innerWidth);
    const [showFilterMvl, setShowFilterMvl] = useState(width >= 768 ? true : false);

    const handleClick = () => {
        setShowFilterMvl(!showFilterMvl);
    };

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (width >= 768) {
            setShowFilterMvl(true)
        } else {
            setShowFilterMvl(false)
        }


    }, [width]);

    return (
        <div>
            <TemplatePage
                // className={'container'}
                padding={'p-cabecera'}

            >


                <div className='flex items-center flex-col'>


                    {forest.isFetching && (forest.data === undefined || forest.data.length === 0) &&
                        <div className=' h-full	mt-5 place-items-center text-center text-airco2-filter-content ' style={{ minHeight: 'calc(100vh - 186px)' }}>

                            <div >
                                <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-blue-600">

                                </div>
                                <p className='mt-2'>{t("listForest.cargando")}</p>
                            </div>
                        </div>
                    }





                    {!forest.isFetching && (forest.data !== undefined || forest.data.length > 0) &&
                        <div className='text-airco2-filter-content px-4 sm:px-6 lg:px-8 py-8 w-full container mx-auto'>
                            <div className='flex flex-wrap justify-between my-4 mx-2'>
                                <h1 className=' font-bold' style={{ fontSize: '1.5rem' }}>{t("listForest.listaMontes")}</h1>
                                <p className='font-italic flex gap-1 mt-2'>
                                    <span>{t("listForest.mostrando")}</span>
                                    <span>{items?.length}</span>
                                    <span>{t("listForest.de")}</span>
                                    <span>{forest.data.length}</span>
                                </p>

                            </div>


                            <button type="button" onClick={() => handleClick()} className="text-sm flex md:hidden">
                                <div className='flex flex-inline mx-2'>
                                    {showFilterMvl ? t("listForest.hideFiltro") : t("listForest.showFiltro")}
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`icon icon-tabler icon-tabler-chevron-up transition-transform duration-200  ${showFilterMvl ? 'transform rotate-180' : ''}`}
                                        width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5"
                                        stroke="#545a6d" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M6 15l6 -6l6 6" />
                                    </svg>
                                </div>
                            </button>

                            <div id="filtermvl" className={`my-4 ${!showFilterMvl ? "hidden" : ""}`}>

                                <div className="flex mb-3  flex-wrap gap-2 justify-between  text-xs items-center filtrosContainer " >


                                    <FilterButton align="left"
                                        categoriesUniq={uniqueProvinceList}
                                        stateCheck={provinceList}
                                        applyCategoryFilter={(data) => applyCategoryFilter(data, 'province')}
                                        nombreFiltro={t("listForest.province")}
                                        className={'filterButtonListForest'}
                                    />

                                    <FilterButton align="left"
                                        categoriesUniq={uniqueStateList}
                                        stateCheck={stateList}
                                        applyCategoryFilter={(data) => applyCategoryFilter(data, 'state')}
                                        nombreFiltro={t("listForest.state")}
                                        className={'filterButtonListForest'}
                                    />
                                    <FilterButton align="left"
                                        categoriesUniq={uniqueForestTypeList}
                                        stateCheck={forestTypeList}
                                        applyCategoryFilter={(data) => applyCategoryFilter(data, 'forestType')}
                                        nombreFiltro={t("listForest.forest")}
                                        className={'filterButtonListForest'}
                                    />


                                    <div style={{ position: 'relative' }} className=''>

                                        <DatePickerComponent
                                            startDate={filtros.startDate}
                                            endDate={filtros.endDate}
                                            handleStartDateChange={handleStartDateChange}
                                            handleEndDateChange={handleEndDateChange}
                                        />
                                        <button
                                            style={{ position: 'absolute', top: '5px', right: '10px' }}
                                            onClick={() => handleDeleteDate()}>

                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-cancel" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#666666" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5" />
                                                <path d="M16 3v4" />
                                                <path d="M8 3v4" />
                                                <path d="M4 11h16" />
                                                <path d="M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                <path d="M17 21l4 -4" />
                                            </svg>


                                        </button>
                                    </div>






                                    <input value={filtros.cadastre}
                                        className=" form-input text-xs italic  bg-white border-gray-200 hover:border-gray-300 filterButtonListForest inputCatastro"
                                        onChange={(e) =>
                                            setFiltros((prevFiltros) => ({
                                                ...prevFiltros,
                                                cadastre: e.target.value,
                                            }))
                                        }
                                        placeholder={t("listForest.cadastre")}
                                        type="text"
                                    />






                                </div>
                            </div>

                            {items?.length === 0 &&

                                <p className='mt-4 text-center text-md'>{t("listForest.noMontes")}</p>

                            }

                            {items?.length > 0 &&

                                <div className='table-container'>


                                    <table className='table-striped text-md'>
                                        <thead className="bg-airco2-principal text-white ">
                                            <tr>
                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("activationDate")}
                                                        className={`${getClassNamesFor("activationDate")} text-underline px-4 py-2`}

                                                    >{t("listForest.fechaModificacion")}</button>


                                                </th>

                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("name")}
                                                        className={`${getClassNamesFor("name")} text-underline p-2`}
                                                    >{t("listForest.name")}</button>
                                                </th>
                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("state")}
                                                        className={`${getClassNamesFor("state")} text-underline p-2`}
                                                    >{t("listForest.estado")}</button>


                                                </th>
                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("forestType")}
                                                        className={`${getClassNamesFor("forestType")} text-underline p-2`}
                                                    >{t("listForest.especie")}</button>
                                                </th>
                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("owner")}
                                                        className={`${getClassNamesFor("owner")} text-underline p-2`}
                                                    >{t("listForest.propietario")}</button>
                                                </th>
                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("province")}
                                                        className={`${getClassNamesFor("province")} text-underline p-2`}
                                                    >{t("listForest.localizacion")}</button>
                                                </th>
                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("size")}
                                                        className={`${getClassNamesFor("size")} text-underline p-2`}
                                                    >{t("listForest.tamaño")}</button>
                                                </th>
                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("cadastre")}
                                                        className={`${getClassNamesFor("cadastre")} text-underline p-2`}
                                                    >Ref. Catastral</button>
                                                </th>


                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("availableCO2")}
                                                        className={`${getClassNamesFor("availableCO2")} text-underline p-2`}
                                                    > tCO₂ {t("listForest.disponible")}</button>


                                                </th>
                                                <th>
                                                    <button
                                                        type="button"
                                                        onClick={() => requestSort("anualCO2")}
                                                        className={`${getClassNamesFor("anualCO2")} text-underline px-4 py-2`}
                                                    >tCO₂</button>


                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((forest, i) => {
                                                return (
                                                    <tr key={i} id={"line-" + forest.id}>
                                                        <td className='px-4 py-2'>{formatDate(forest.activationDate ? forest.activationDate : forest.validationDate)}</td>
                                                        <td className='p-2'>{forest.name}</td>
                                                        <td className='p-2'>
                                                            <div className='rounded-lg px-2 py-1 text-center  text-airco2-general font-semibold' style={{ backgroundColor: getStatusInfo(forest.state).color }}>
                                                                {getStatusInfo(forest.state).text}

                                                            </div>



                                                        </td>
                                                        <td className='p-2'>
                                                            {forest.forestType.includes(',') ? (

                                                                <span className='flex gap-1 items-center'>
                                                                    <span> {forest.forestType.split(', ')[0]} </span>

                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={<Tooltip id="button-tooltip">{forest.forestType}</Tooltip>}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#666666" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                                            <path d="M9 12h6" />
                                                                            <path d="M12 9v6" />
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                </span>
                                                            ) : (

                                                                forest.forestType
                                                            )}
                                                        </td>
                                                        <td className='p-2'>{forest.owner}</td>
                                                        <td className='p-2'>{capitalizeFirstLetter(forest.province)}</td>
                                                        <td className='p-2 text-center'>{forest.size}</td>
                                                        <td className='p-2'>
                                                            {forest.cadastre.includes(',') ? (

                                                                <span className='flex gap-1 items-center'>
                                                                    <a href={`https://www1.sedecatastro.gob.es/Cartografia/mapa.aspx?refcat=${forest.cadastre.split(', ')[0]}`} target="_blank" rel="noopener noreferrer">
                                                                        <button> {forest.cadastre.split(', ')[0]} </button>
                                                                    </a>

                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={<Tooltip id="button-tooltip">{forest.cadastre}</Tooltip>}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#666666" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                                            <path d="M9 12h6" />
                                                                            <path d="M12 9v6" />
                                                                        </svg>
                                                                    </OverlayTrigger>
                                                                </span>
                                                            ) : (
                                                                <a href={`https://www1.sedecatastro.gob.es/Cartografia/mapa.aspx?refcat=${forest.cadastre}`} target="_blank" rel="noopener noreferrer">
                                                                    <button>
                                                                        {forest.cadastre}
                                                                    </button>
                                                                </a>


                                                            )}
                                                        </td>
                                                        <td className='p-2 text-center'>{formatThousands(forest.availableCO2)}</td>
                                                        <td className='px-4 py-2 text-center'>{formatThousands(forest.anualCO2)}</td>



                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }






                        </div>



                    }

                </div>




            </TemplatePage >

        </div>

    )
}