import React from 'react'
import CardCatalogo from '../components/CardCatalogo';
import Carousel from 'react-bootstrap/Carousel'
import FsLightbox from "fslightbox-react";
import { useState } from 'react';
import expand from '../images/expand.svg'
import { useTranslation } from 'react-i18next'
import profile from '../images/profile.svg'
import fondo from '../images/Huye_del_Green.png'

export default function ProviderBody({
  providerid
}) {


  const { t } = useTranslation();
  let imgPrincipal = providerid.gallery.filter((x) =>
    x.principal === true
  )


  const items = providerid.productsList

  const regionesTotal = items.map((x) => x.region)

  const regiones = regionesTotal.filter((item, index) => {
    return regionesTotal.indexOf(item) === index;
  })


  const allImages = providerid.gallery.filter((x) => x.principal !== true).map((y) => y.path)

  const [toggler, setToggler] = useState(false);

  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={allImages}
        type="image"
      />

      <div className={`grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out text-airco2-general `}>

        {/* Provider background */}
        <div className="relative h-80 bg-gray-200">

          {(allImages.length !== 0) &&
            <button className='absolute'
              style={{ bottom: '50px', left: '92vw', zIndex: '9' }}
              onClick={() => setToggler(!toggler)}>
              <img alt='expandir imagen' width={'30px'} src={expand}></img>

            </button>
          }
          {allImages.length !== 0 ?
            <Carousel indicators={false} controls={allImages.length > 1 ? true : false} className='carousel-img'>
              {allImages.map((x, key) =>
                <Carousel.Item key={key} >
                  <div
                    className="d-block w-100 h-80"
                    style={{ backgroundImage: `url(${x})`, backgroundSize: 'cover', backgroundPosition: 'center' }}



                  />

                </Carousel.Item>
              )}


            </Carousel>
            :
            <Carousel indicators={false} controls={false} className='carousel-img'>

              <Carousel.Item >
                <div
                  className="d-block w-100 h-80"
                  style={{ backgroundImage: `url(${fondo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}



                />

              </Carousel.Item>



            </Carousel>




          }


        </div>

        {/* Content */}
        <div className="container relative px-4 sm:px-6 pb-8">

          {/* Pre-header */}
          <div className="-mt-16 mb-6 sm:mb-3">

            <div className="flex flex-col items-center sm:flex-row sm:justify-between sm:items-end">

              {/* Avatar */}
              {/* <div className="inline-flex -ml-1 -mt-1 mb-4 sm:mb-0"> */}
              <div className="mt-4">
                {(imgPrincipal.length !== 0) ?
                  <img className="rounded-full border-4 border-white" src={imgPrincipal[0].path} width="128" height="128" alt="Avatar" />
                  :
                  <img className="rounded-full border-4 border-white" src={profile} width="128" height="128" alt="Avatar" />

                }
              </div>

              {/* Actions */}
              {/* <div className="flex space-x-2 sm:mb-2">
            <button className="p-1.5 shrink-0 rounded border border-gray-200 hover:border-gray-300 shadow-sm">
              <svg className="w-4 h-1 fill-current text-gray-400" viewBox="0 0 16 4">
                <circle cx="8" cy="2" r="2" />
                <circle cx="2" cy="2" r="2" />
                <circle cx="14" cy="2" r="2" />
              </svg>
            </button>
            <button className="p-1.5 shrink-0 rounded border border-gray-200 hover:border-gray-300 shadow-sm">
              <svg className="w-4 h-4 fill-current text-indigo-500" viewBox="0 0 16 16">
                <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7Zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8Z" />
              </svg>
            </button>
            <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white">
              <svg className="fill-current shrink-0" width="11" height="8" viewBox="0 0 11 8">
                <path d="m.457 4.516.969-.99 2.516 2.481L9.266.702l.985.99-6.309 6.284z" />
              </svg>
              <span className="ml-2">Following</span>
            </button>
          </div> */}

            </div>

          </div>

          {/* Header */}
          <header className="text-center sm:text-left mb-6">
            {/* Name */}
            <div className="inline-flex items-start mb-2">
              <span className="text-2xl  font-bold">{providerid.name}</span>
              <svg className="w-4 h-4 fill-current shrink-0 text-yellow-500 ml-2" viewBox="0 0 16 16">
                <path d="M13 6a.75.75 0 0 1-.75-.75 1.5 1.5 0 0 0-1.5-1.5.75.75 0 1 1 0-1.5 1.5 1.5 0 0 0 1.5-1.5.75.75 0 1 1 1.5 0 1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1 0 1.5 1.5 1.5 0 0 0-1.5 1.5A.75.75 0 0 1 13 6ZM6 16a1 1 0 0 1-1-1 4 4 0 0 0-4-4 1 1 0 0 1 0-2 4 4 0 0 0 4-4 1 1 0 1 1 2 0 4 4 0 0 0 4 4 1 1 0 0 1 0 2 4 4 0 0 0-4 4 1 1 0 0 1-1 1Z" />
              </svg>
            </div>
            {/* Bio */}
            <div className="text-sm mb-3"></div>

          </header>



          {/* Provider content */}
          <div className="flex flex-col xl:flex-row xl:space-x-16 md:px-4 lg:px-10 xl:px-14">

            {/* Main content */}
            <div className="w-full space-y-5 mb-8 xl:mb-0">

              {/* About Me */}
              <div>

                <div className="text-sm space-y-2" >
                  <p >{providerid.summary}</p>
                  <p dangerouslySetInnerHTML={{ __html: providerid.description }}></p>
                  {/* <p>{providerid.description}</p> */}
                </div>
              </div>

              {/* Meta */}
              {/* <div className="flex flex-wrap justify-center sm:justify-start space-x-4 md:px-4 lg:px-10 xl:px-14"> */}
              <div className=" flex flex-wrap space-x-4">
                {regiones.map((x, y) =>

                  <div key={y} className="flex items-center">
                    <svg className="w-4 h-4 fill-current shrink-0 " viewBox="0 0 16 16">
                      <path d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z" />
                    </svg>
                    <span className="text-sm font-medium whitespace-nowrap  ml-2">{x}</span>
                  </div>
                )}

              </div>

              {/* Work History */}
              <div className='text-center items-center'>
                <h2 className=" text-xl font-semibold mb-2">{t("provider.misMontes")}</h2>
                <div className="bg-white p-4  rounded-sm ">
                  <div className='container-card-market' >
                    <CardCatalogo
                      products={items}
                    />
                  </div>
                </div>
              </div>

            </div>


          </div>

        </div>

      </div>
    </>

  )
}
