import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ProviderBody from '../components/ProviderBody';
import profile from '../images/profile.svg';
import TemplatePage from './TemplatePage';

export default function Provider() {
  const { t } = useTranslation();

  const pathParams = useParams();
  const idprovider = pathParams.id



  // FETCH PARA provider-id

  const [providerid, setproviderid] = useState(undefined);
  const [metatagsProduct, setMetatagsProduct] = useState({ title: "", desc: "", image: "" })
  const [spinner, setSpinner] = useState(false);


  useEffect(() => {
    setSpinner(true);
    fetch(`${process.env.REACT_APP_MP_PROVIDER}/${idprovider}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // Authorization: `Bearer ${UserService.getToken()}`,
      },
    })
    .then(j => j.json())
    .then(r => {
      setproviderid(r)
      let principalGallery = r.gallery.filter(x => x.principal);
      setMetatagsProduct({
        title: "Market airCO2 | " + r.name,
        desc: r.summary || t("meta.home.description"),
        image: principalGallery.length > 0 ? principalGallery[0].path : profile
      });
      setSpinner(false);
    })
  }, [])

  return (
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">

      <TemplatePage h1={metatagsProduct.title}
        title={metatagsProduct.title}
        desc={metatagsProduct.desc}
        image={metatagsProduct.image}>

        {spinner &&
          <div className='flex justify-center items-center gap-2 h-80'>
            <span>{t("detail.cargandoForestal")}</span>
            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-blue-600" role="status">
              <span className="visually-hidden">{t("detail.cargandoForestal")}</span>
            </div>
          </div>
        }
        
        {(providerid !== undefined) &&
          <ProviderBody
            providerid={providerid} />
        }

      </TemplatePage>

    </div>

  )
}
