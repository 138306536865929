import { useAuthContext } from '../context/authContext';


const RenderOnAnonymous = ({ children }) => {
    const { isLoggedIn } = useAuthContext();

    return !isLoggedIn() ? children : children
};

export default RenderOnAnonymous
