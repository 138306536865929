
import CartItems from '../components/CartItems';
import { useAuthContext } from '../context/authContext';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FormAdress from '../components/FormAdress';
import { formatThousands } from '../utils/Utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TemplatePage from './TemplatePage';
import { Spinner } from 'react-bootstrap';
import Banner from '../components/Banner';

export default function FinishOrder() {
  const { t } = useTranslation();
  const auth = useAuthContext();

  const pathParams = useParams();

  const iddetail = pathParams.id


  const [errorMessage, setErrorMessage] = useState({
    type: '',
    message: '',
    open: false,
  });


  let [envF, updateEnv] = useState(false);
  const [show, setShow] = useState(false);


  const [address, setAddress] = useState({
    addressData: [],
    isFetching: false,
    error: false,
  });

  useEffect(() => {
    const fetchAddress = async () => {
      try {

        const response = await axios.get(`${process.env.REACT_APP_MP_ADDRESS}`, {


          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        });
        setAddress({ addressData: response.data, isFetching: true });

      } catch (exception) {

        setAddress({ addressData: address.addressData, isFetching: true, error: true });
      }
    };
    fetchAddress();
  }, []);
  const actualizar = (v) => {
    updateEnv(v)
    // setShow(v[1])
    setAddress({ addressData: [...address.addressData, v], isFetching: true });

  }

  const modalACt = (v) => {
    setShow(v)
  }




  const [selectedId, setSelectedId] = useState(null);
  const [formData, setFormData] = useState({});


  const handleClick = (id) => {

    setSelectedId(id);


  }
  
 
 
  useEffect(() => {
    const principalAddress = address.addressData.length === 0 ? "" : address.addressData.find(x => x.principal === true);
    if (principalAddress !== undefined) setSelectedId(principalAddress.id);
  }, [address.addressData])



  const handleShow = () => setShow(true);

  const [shoppingId, setShoppingId] = useState({
    shoppingData: [],
    isFetching: false,
    error: false,
  });

  //cambiar
  useEffect(() => {
    const fetchShopping = async () => {
      try {

        const response = await axios.get(` ${process.env.REACT_APP_MP_ORDER_ID}/${iddetail}`, {

          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        });
        setShoppingId({ shoppingData: response.data, isFetching: true });

      } catch (exception) {
        setShoppingId({ shoppingData: shoppingId.shoppingData, isFetching: true, error: true });
      }
    };
    fetchShopping();
  }, []);


  // const idshoppingId = shoppingId.shoppingData.length !== 0 ? shoppingId.shoppingData.id : ""


  // const IdshoppingId = shoppingId.shoppingData.length !== 0 ? shoppingId.shoppingData.id : ""

  const [isLoading, setIsLoading] = useState(false);
  const handlerShopping = () => {
    setIsLoading(true);



    const fetchUpdateShopping = async () => {
      try {

        axios.get(`${process.env.REACT_APP_MP_ORDER}orderId=${iddetail}&addressId=${selectedId}`,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${auth.getToken()}`
            }
          })
          .then((response) => {
            window.location.href = response.data;
          }, (error) => {
            console.log(error)
            setErrorMessage({ ...errorMessage, type: "error", message: t("orders.error"), open: true });
            setIsLoading(false);
          });
      } catch (exception) {
        console.log(exception)
        setIsLoading(false);
      }
    };
    fetchUpdateShopping();

  }

  const [terminos, setTerminos] = useState(false);
  const setOpen = (value) => {
    setErrorMessage({ ...errorMessage, open: value });
  }


  return (
    <div className="flex h-screen overflow-hidden">


      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">





        <TemplatePage
          padding={'pt-20'}
          h1={t("meta.finishOrder.title")}
        >
          <div className="lg:relative lg:flex">

            {/* Content */}
            <div className="px-4 sm:px-6 lg:px-8 py-8 lg:grow lg:pr-8 xl:pr-16 2xl:ml-[80px]" >

              <div className=" container lg:max-w-[750px] lg:mx-auto">

                {/* Cart items */}
                <div className="mb-6 lg:mb-0">

                  <header className="  mb-2 flex justify-between" >
                    {/* Title */}
                    <h1 className="text-2xl md:text-3xl text-airco2-filter-content">{t("orders.datafactura")} ✨</h1>

                  </header>

                  {/* direcciones guardadas */}
                  <div className='pt-2 pb-4 grid gap-4 grid-cols-1  md:grid-cols-2 text-airco2-general'>


                    {!address.isFetching ? ( 
                      <div className="w-12 h-12 spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary" role="status"></div>
                    ) : (
                      (address.addressData === undefined || address.addressData === null || address.addressData.length === 0) ? (
                        <div className="text-airco2-general font-bold">{t("orders.noDireccion")}</div>
                      ) : (
                        address.addressData.map((x, key) =>

                          <div key={key} className=" mt-1 mb-1 bg-white p-4 shadow-lg rounded-sm border border-gray-200">
                            {/* Order details */}
                            <ul className="mb-4">
                              <li>
                                <label className="flex items-center">

                                  <input type="radio" name="adress" className="form-checkbox"
                                    onClick={() => handleClick(x.id)} checked={x.id === selectedId}
                                  />

                                  <span className="text-sm font-medium ml-2"></span>
                                </label>
                              </li>
                              <li className="text-sm w-full flex justify-between py-3 border-b border-gray-200">
                                <div className='text-airco2-principal font-bold flex gap-1 items-center'>
                                  <span className='mt-1'>{x.nameAddress}</span>
                                  {(x.principal === true) &&
                                    <span className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16"> <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" fill="#e6c542"></path> </svg></span>
                                  }
                                </div>



                                <div className="font-medium  mt-1">{x.person}</div>
                              </li>
                              <li className="text-sm w-full flex justify-between py-3 border-b border-gray-200">
                                <div> {t("orders.calle")} {x.street}   </div>
                                <div className="font-medium "> {x.pc}</div>
                              </li>
                              <li className="text-sm w-full flex justify-between py-3 border-b border-gray-200">
                                <div>{x.city}</div>
                                <div className="font-medium ">{x.state}</div>
                                {/* <div>{x.country}</div> */}
                              </li>
                              <li className="text-sm w-full flex justify-between py-3 border-b border-gray-200">
                                <div>{x.region}</div>
                                <div className="font-medium ">{x.country}</div>
                              </li>
                            </ul>



                          </div>
                        ))

                    )}
                  </div>

                  {/* añadir nuevas direcciones */}

                  <div className=''>
                    <div className="text-airco2-general font-semibold mb-4">  
                    
                    <button className='btn bg-airco2-principal text-white' onClick={() => handleShow()}>{t("orders.newDireccion")}</button></div>

                    <FormAdress
                      formData={formData}
                      actualizar={actualizar}
                      modalACt={modalACt}
                      show={show}

                    />

                    <div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            {/* Sidebar */}
            <div>
              <div className=" text-airco2-general lg:sticky lg:top-16 bg-gray-50 lg:overflow-x-hidden lg:overflow-y-auto no-scrollbar lg:shrink-0 border-t lg:border-t-0 lg:border-l border-gray-200 lg:w-[400px] xl:w-[382px] 2xl:w-[calc(382px+80px)] lg:h-[calc(100vh-64px)]">
                <div className="py-8 px-4 lg:px-8 2xl:px-12">
                  <div className="max-w-sm mx-auto lg:max-w-none">
                    <h2 className="text-2xl  font-bold mb-6">{t("orders.resumen")}</h2>
                    <div className="space-y-6">

                      {/* Order summary */}
                      <div>
                        <div className=" font-semibold mb-2">{t("orders.detalles")}</div>
                        {
                          (!shoppingId.isFetching)
                          && <div className='text-center text-xs '>{t("cat.cargando")}</div>}


                        {shoppingId.isFetching && shoppingId.shoppingData.length === 0 ?
                          (shoppingId.error) ?
                            <div className='text-center text-xs'>{t("modalShop.loginCarrito")}.</div>
                            :
                            (shoppingId.isFetching && !shoppingId.error) ?
                              <div className='text-center text-xs '>{t("modalShop.noProducto")}.</div>
                              : ""
                          :




                          (shoppingId.shoppingData.orderLineList === undefined || shoppingId.shoppingData.orderLineList === null || shoppingId.shoppingData.orderLineList.length === 0) ?
                            <div className='text-center text-xs '>{t("modalShop.noProducto")}.</div>
                            :

                            <CartItems
                              shoppingPay={shoppingId.shoppingData}

                            />
                        }
                        {shoppingId.shoppingData.total === undefined || shoppingId.shoppingData.total === null || shoppingId.shoppingData.total.length === 0 ? '' :

                          <ul className="mb-4">

                            <li className="text-sm w-full flex justify-between py-3 border-b border-gray-200">
                              <div>{t("orders.subtotal")}</div>

                              <div className="font-medium ">{formatThousands(shoppingId.shoppingData.total.toFixed(2))}€</div>

                            </li>


                          </ul>
                        }
                      </div>
                      <div className="text-xs  italic text-center">
                        <label className="flex items-center">
                          <input type="checkbox"
                            className="form-checkbox"
                            name="consent"
                            style={{ border: '1px solid white' }}

                            onChange={e => { setTerminos(e.target.checked) }}
                          />

                          <span className="text-xs ml-2">{t("orders.aceptopago")} *.</span>
                        </label>


                      </div>
                      <div className="mt-6">



                        <div className="mb-4">
                          {shoppingId.shoppingData.total === undefined ||
                            shoppingId.shoppingData.total === null ||
                            shoppingId.shoppingData.total.length === 0 ? (
                            ""
                          ) : 
                          
                          
                           terminos === true &&  ( address?.addressData.length !== 0) ? (
                            <button
                              className="btn bg-airco2-principal w-full text-white gap-2"
                              onClick={handlerShopping}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <Spinner />
                              ) : (
                                <>
                                  {t("orders.pagar")}
                                  <span className="text-sm italic">
                                    {formatThousands(shoppingId.shoppingData.total.toFixed(2))}
                                    € + IVA{" "}
                                  </span>
                                </>
                              )}
                            </button>
                          ) : (
                            <button
                              
                              className="btn rounded-lg  w-full text-white gap-2" style={{backgroundColor:' #ccc'}}
                  
                              onClick={()=>  setErrorMessage({ ...errorMessage, type: "warning", message: t("orders.direccionYterminos"), open: true })}
                             
                            >
                              {t("orders.pagar")}
                              <span className="text-sm italic"
                           >
                                
                                {formatThousands(shoppingId.shoppingData.total.toFixed(2))}€ + IVA{" "}
                              </span>
                            </button>
                           
                          )}
                            <Banner  type={errorMessage.type} children={errorMessage.message} open={errorMessage.open} setOpen={setOpen} className="mb-3 mt-3 w-ful"></Banner>
                        </div>



                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </TemplatePage>

      </div>

    </div>
  );
}
