import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'

export default function Contador({
    expiredDate,
    contador,
    separacion,
    gap

}) {


    const { t } = useTranslation();

    const calculateTimeLeft = () => {
        // let year = new Date().getFullYear();
        // let difference = +new Date(`10/01/${year}`) - +new Date();
        let difference = + new Date(`${expiredDate}`) - +new Date();
      
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
                horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutos: Math.floor((difference / 1000 / 60) % 60),
                segundos: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }


    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    // const [year] = useState(new Date().getFullYear());
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });

 const horas = timeLeft.horas>9? timeLeft.horas : `0${timeLeft.horas}`
 const minutos = timeLeft.minutos>9? timeLeft.minutos : `0${timeLeft.minutos}`
 const segundos = timeLeft.segundos>9? timeLeft.segundos : `0${timeLeft.segundos}`
    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });

const hora1 =horas.toString().substring(0,1)
const hora2 =horas.toString().substring(1,2)
const minutos1 =minutos.toString().substring(0,1)
const minutos2=minutos.toString().substring(1,2)
const segundos1 =segundos.toString().substring(0,1)
const segundos2=segundos.toString().substring(1,2)

    return (
        <div>


            {timerComponents.length ?

                //   timerComponents : 
           
                <div className={`containerContador ${gap}`}>
                    {/* <p className=`${contador}`>{timeLeft.dias}</p> */}
                  
                    
                    <p className={`${contador}`}>{hora1}</p>
                    
                    <p className={`${contador}`}>{hora2}</p>
                    {/* <p className="textocontador">Horas</p></p> */}
                    <div className={`${separacion}`}>
                        <span>:</span>
                        </div>
                        <p className={`${contador}`}>{minutos1}</p>
                    <p className={`${contador}`}>{minutos2}</p>
                    {/* <p className="textocontador">Minutos</p></p> */}
                    <div className={`${separacion}`}>:</div>
                    <p className={`${contador}`}>{segundos1}</p>
                    <p className={`${contador}`}> {segundos2}</p>
                    {/* <p className="textocontador">Segundos</p></p> */}
                
               </div>


                :

                <span>{t("shop.timesUp")}</span>}
        </div>
    );
}

