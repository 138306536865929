import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '../context/authContext';
import pago from '../images/pago2.svg';
import pagoKo from '../images/pagoKo.svg';
import { formatThousands } from '../utils/Utils';
import { useTranslation } from 'react-i18next';
import CartItems from '../components/CartItems';
import { Link } from 'react-router-dom';
import TemplatePage from './TemplatePage';
import { handleClick } from '../components/Utils';


export default function PaymentResponse() {
  const url = window.location.href;
  const word = process.env.REACT_APP_STRIPE_PREFIX;

 
  let parametro = ''


  const paramIndex = url.indexOf("?");
  if (paramIndex !== -1) {
    const param = url.substring(paramIndex + 1);
    parametro = param.split('=')[1];
    
  } 


  const { t } = useTranslation();
  const auth = useAuthContext();

  const payOK = url.includes(word) ? true : false

  const [pay, setPay] = useState({
    payData: [],
    isFetching: false,
    error: false,
  });



  useEffect(() => {
    if (payOK !== true) return () => { }

    // if(payOK === true) return
    const fetchPay = async () => {
      try {

        const response = await axios.get(`${process.env.REACT_APP_MP_ORDER_ID}?checkoutSessionId=${parametro}
      `, {

          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        });
        setPay({ payData: response.data, isFetching: true });

      } catch (exception) {
        setPay({ payData: pay.payData, isFetching: true, error: true });
      }
    };
    fetchPay();
  }, [])


  const payTotal = pay.payData.total
  const payTotalIva = pay.payData.totalVat
  const shoppingPay = pay.payData




  return (
    <div className="flex h-screen overflow-hidden" style={{ backgroundColor: '#ebebe9' }}>
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <TemplatePage
          padding={'p-cabecera'}
          h1={t("meta.stripePayment.title")}
        >

          {payOK === true ?
            <div className="lg:relative lg:flex text-airco2-general">

              {/* Content */}
              <div className="px-4 sm:px-6 lg:px-8 py-8 lg:grow lg:pr-8 xl:pr-16 2xl:ml-[80px] flex self-center  justify-center" >

                <div className="lg:max-w-[640px] lg:mx-auto ">

                  {/* Cart items */}
                  <div className="mb-6 mt-2 lg:mb-0 ">


                    <div className=' pt-1 pb-4 flex flex-column items-center'>

                      <h2 className="text-xl md:text-2xl  font-bold mb-6">{t("pay.pagoexito")}✨</h2>
                      <img className='' alt='pago ok' width={'500'} src={pago} ></img>
                      <h3 className="text-lg md:text-xl  mt-1 italic">{t("pay.graciasCompensar")}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:sticky lg:top-16 bg-gray-50 lg:overflow-x-hidden lg:overflow-y-auto no-scrollbar lg:shrink-0 border-t lg:border-t-0 lg:border-l border-gray-200 lg:w-[400px] xl:w-[382px] 2xl:w-[calc(382px+80px)] lg:h-[calc(100vh-182px)]">
                <div className="py-8 px-4 lg:px-8 2xl:px-12">
                  <div className="max-w-sm mx-auto lg:max-w-none">
                    <h2 className="text-2xl  font-bold mb-6 mt-3">{t("orders.resumen")}</h2>
                    <div className="space-y-6">

                      {/* Order summary */}

                      <div>
                        <div className=" font-semibold mb-2">{t("orders.detalles")}</div>
                        {
                          (pay.payData === undefined || pay.payData === null || pay.payData.orderLineList === null || pay.payData.orderLineList === undefined || pay.payData.orderLineList.length === 0
                          ) ?
                            t("orders.cargando") :

                            <CartItems
                              shoppingPay={shoppingPay}
                            />
                        }

                        <ul className="mb-4">
                          <li className="text-sm w-full flex justify-between py-3 border-b border-gray-200">
                            <div>{t("orders.subtotal")}</div>
                            {payTotal === undefined || payTotal === null ? "" :
                              <div className="font-medium ">{formatThousands(payTotal.toFixed(2))}€</div>
                            }
                          </li>
                          <li className="text-sm w-full flex justify-between py-3 border-b border-gray-200">
                            <div>{t("orders.conIva")}</div>
                            {payTotalIva === undefined || payTotalIva === null ? "" :
                              <div className="font-medium text-green-600">{formatThousands(payTotalIva.toFixed(2))}€</div>
                            }
                          </li>
                        </ul>
                      </div>






                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className=' text-airco2-general container flex flex-row text-center justify-center items-center' style={{ backgroundColor: '#ebebe9', minHeight: 'calc(100vh - 184px)' }}>
              <div className=''>
                <h2 className=" pt-1 text-xl md:text-2xl  font-bold mb-6">{t("pay.pagofallido")}</h2>
                <div className='flex-row'>

                  <div className='flex justify-center'>

                    <img alt='notFound' src={pagoKo} width={'380'}></img>

                  </div>

                  <div className='w-full flex flex-row justify-center'>
                    <div className='px-2 flex  mt-1 text-xl gap-2 flex-wrap'>
                      <span> {t("pay.dirigePedidos")}</span>

                      <Link onClick={handleClick} to={`/account/orders`}>
                        <span style={{ color: '#8DD4BD', fontStyle: 'italic', fontWeight: 'bold' }}>{t("pay.pedidos")}  </span>
                      </Link>
                      <span> {t("pay.vuelveintentar")}</span>
                    </div>

                  </div>

                </div>
              </div>
            </div>

          }

        </TemplatePage>
      </div>

    </div>
  )
}
