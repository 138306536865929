import React from "react";
import Footer from '../components/Footer';
import Header from '../components/Header';
import PageMetatags from "../utils/Metatags";

export default function TemplatePage({ children, className, padding, id, h1, title, desc, image }) {

    return (
        <main>
            <PageMetatags image={image} titleDynamic={title} descDynamic={desc} />

            {/* Header */}
            <Header></Header>
            {/* Contenido */}
            {/* <div className={padding} id={id}></div> */}
            <div className={`relative ${className} ${padding} `} id={id} style={{ minHeight: 'calc(100vh - 94px)' }} >
                {h1 && h1 !== "" && <h1 className='hidden'>{h1}</h1>}
                {children}
            </div>
            {/* Footer */}
            <Footer></Footer>
        </main>
    )

}