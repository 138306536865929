import React from 'react';


import { HashLink } from 'react-router-hash-link';



function PaginationClassic(props) {


  const page = "/catalogo#"
 
  const isNotCatalogo = window.location.pathname !== "/catalogo";

  return (

    <div className="flex justify-center text-airco2-general">

      <nav className="flex" role="navigation" aria-label="Navigation">

        {/* boton pagina primera */}
        <div className="mr-2">
          <HashLink to={isNotCatalogo ? '' : `${page}catalogoStart`}  smooth={true} offset={50} duration={500} aria-label='lleva a primera pagina'
            className=" text-airco2-general inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white  border border-gray-200 text-decoration-none  shadow-sm"
            onClick={props.firstHandler}
          >
            <span className="sr-only" >Previous</span><wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 8 16">
              <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
            </svg>
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
            </svg>
          </HashLink>
        </div>

        {/* boton pagina anterior */}
        <div className="mr-2">
          <HashLink to={isNotCatalogo ? '' : `${page}catalogoStart`}  smooth={true} offset={50} duration={500} aria-label='lleva a pagina anterior'
            className="text-airco2-general inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white  border border-gray-200  texhover:t-indigo-600 shadow-sm "
            onClick={props.prevHandler}>

            <span className="sr-only" >Previous</span><wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
            </svg>
          </HashLink>
        </div>
        {/*botones para paginar*/}

        <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
          {/* boton puntos */}
          {props.currentpage > 1 &&
            <li>
              <HashLink aria-label='hay mas paginas' className="text-airco2-general inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white  border border-gray-200  text-decoration-none hover:text-indigo-600"
               to={isNotCatalogo ? '' : `${page}catalogoStart`}  smooth={true} offset={50} duration={500}
                onClick={props.pointFirst}
              >...</HashLink>
            </li>
          }


          {/* boton pagina anterior actual */}
          {props.currentpage >= 1 &&
            <li>
              <HashLink aria-label='pagina anterior actual' className="text-airco2-general inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white  border border-gray-200  text-decoration-none hover:text-indigo-600"
                onClick={props.prevHandler} to={isNotCatalogo ? '' : `${page}catalogoStart`}  smooth={true} offset={50} duration={500}>
                {props.currentpage}</HashLink>
            </li>
          }

          {/* boton pagina actual */}
          <li className='bg-airco2-principal '>
            <HashLink aria-label='pagina actual' className=" text-airco2-general inline-flex items-center justify-center leading-5 px-3.5 py-2  text-white font-bold" to={isNotCatalogo ? '' : `${page}catalogoStart`} smooth={true} offset={50} duration={500}>
              {props.currentpage + 1}</HashLink>
          </li>

          {/* boton paginadespues actual */}
          {props.currentpage < props.lastPage &&
            <li>
              <HashLink aria-label='pagina posterior actual' className=" text-airco2-general inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white  border border-gray-200  text-decoration-none "
                onClick={props.nextHandler}to={isNotCatalogo ? '' : `${page}catalogoStart`} smooth={true} offset={50} duration={500}>
                {props.currentpage + 2}
              </HashLink>
            </li>
          }
          {/* boton puntos */}
          {props.currentpage < props.lastPage - 1 &&
            <li>
              <HashLink aria-label='hay mas paginas' className=" text-airco2-general inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-white  border border-gray-200  text-decoration-none hover:text-indigo-600" to={isNotCatalogo ? '' : `${page}catalogoStart`}  smooth={true} offset={50} duration={500}
                onClick={props.pointLast}
              >...</HashLink>
            </li>
          }
          
        </ul>




        {/* boton pagina siguiente */}
        <div className="ml-2">
          <HashLink aria-label='pagina siguiente'
            className=" text-airco2-general inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white  border border-gray-200  text-decoration-none shadow-sm"
            to={isNotCatalogo ? '' : `${page}catalogoStart`} smooth={true} offset={50} duration={500}
            onClick={props.nextHandler}>
            <span className="sr-only" >Next</span><wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
            </svg>
          </HashLink>
        </div>

        {/* boton ultima pagina */}
        <div className="ml-2">
          <HashLink aria-label='inicio pagina'
            className=" text-airco2-general inline-flex items-center justify-center rounded leading-5 px-2.5 py-2 bg-white  border border-gray-200  text-decoration-none  shadow-sm"
            to={isNotCatalogo ? '' : `${page}catalogoStart`}  smooth={true} offset={50} duration={500} onClick={props.lastHandler}>
            <span className="sr-only" >Ultima</span><wbr />
            <svg className="h-4 w-4 fill-current" viewBox="0 0 8 16">
              <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
            </svg>
            <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
              <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
            </svg>
          </HashLink>
        </div>

      </nav>
    </div>
  );
}

export default PaginationClassic;