import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';




function ListInfoExtra({ productsid }) {
  const { t } = useTranslation();
  const titulos = ['contexto', 'zona', 'gestion', 'calculo', 'monitoreo', 'beneficios', 'localizacion']
  return (
    <>
      <Accordion className='text-airco2-general' defaultActiveKey="0">
        {productsid.context &&
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t(`infoExtra.${titulos[0]}`)}</Accordion.Header>
            <Accordion.Body>
            <span id="custom-list-container" dangerouslySetInnerHTML={{ __html: productsid.context }}></span>
            </Accordion.Body>
          </Accordion.Item>
        }
        {productsid.zone &&
          <Accordion.Item eventKey="1">
            <Accordion.Header>{t(`infoExtra.${titulos[1]}`)}</Accordion.Header>
            <Accordion.Body>
               <span id="custom-list-container"  dangerouslySetInnerHTML={{ __html: productsid.zone }}></span> 
            </Accordion.Body>
          </Accordion.Item>
        }
        {productsid.management &&
          <Accordion.Item eventKey="2">
            <Accordion.Header>{t(`infoExtra.${titulos[2]}`)}</Accordion.Header>
            <Accordion.Body>
               <span id="custom-list-container"  dangerouslySetInnerHTML={{ __html: productsid.management }}></span> 
            </Accordion.Body>
          </Accordion.Item>
        }
        {productsid.calculation &&
          <Accordion.Item eventKey="3">
            <Accordion.Header>{t(`infoExtra.${titulos[3]}`)}</Accordion.Header>
            <Accordion.Body>
            <span id="custom-list-container" dangerouslySetInnerHTML={{ __html: productsid.calculation }}></span>




            </Accordion.Body>
          </Accordion.Item>
        }
        {productsid.monitoring &&
          <Accordion.Item eventKey="4">
            <Accordion.Header>{t(`infoExtra.${titulos[4]}`)}</Accordion.Header>
            <Accordion.Body>
               <span id="custom-list-container"  dangerouslySetInnerHTML={{ __html: productsid.monitoring }}></span> 
            </Accordion.Body>
          </Accordion.Item>
        }
        {   productsid.benefits &&
        <Accordion.Item eventKey="5">
          <Accordion.Header>{t(`infoExtra.${titulos[5]}`)}</Accordion.Header>
          <Accordion.Body>
             <span id="custom-list-container"  dangerouslySetInnerHTML={{ __html: productsid.benefits }}></span> 
          </Accordion.Body>
        </Accordion.Item>
}

      </Accordion>





    </>
  );
}

export default ListInfoExtra;