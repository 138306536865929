import React from 'react'

import click from '../images/A_dos_clicks.png'
import huye from '../images/Huye_del_Green.png'

import { useTranslation } from 'react-i18next';

export default function HomeStory() {

  const { t } = useTranslation();
  return (
    <section>

      {/* texto 1 */}
      <div className="max-w-6xl mx-auto pt-8 ">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-airco2-home-title mt-4 pb-2 md:pb-4">{t("home.plantarArboles")}</h2>
          <p className="text-airco2-home-subtitle">{t("home.cuidemosTenemos")}</p>
        </div>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-4 md:py-16">

            <article className="max-w-sm mx-auto space-y-5 md:max-w-none md:flex md:items-center md:space-y-0 md:space-x-8 lg:space-x-16">
              {/* Image */}
              <div className="relative block group overflow-hidden md:w-1/2" to="/blog-post" data-aos="fade-down">
                <img className="w-full aspect-[16/9] md:aspect-[27/17] object-cover group-hover:scale-105 transition duration-700 ease-out" src={click} width="540" height="340" alt="News 01" />
                <div className="absolute top-6 right-6">
                </div>
              </div>
              {/* Content */}
              <div className="md:w-1/2" data-aos="fade-up ">
                <header>
                  <h3 className="text-airco2-home-title mb-3">
                    {t("home.clicks")}
                  </h3>
                </header>
                <p className="text-airco2-home-subtitle"> {t("home.compensacionEfectiva")}</p>

              </div>
            </article>

          </div>
        </div>

      </div>
      {/* texto2 */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-4 md:py-16">

          <article className="max-w-sm mx-auto space-y-5 md:max-w-none md:flex md:items-center md:space-y-0 md:space-x-8 lg:space-x-16">

            {/* Content */}
            <div className="md:w-1/2" data-aos="fade-up">
              <header>
                <h3 className="text-airco2-home-title mb-3">
                  {t("home.greenwashing")}
                </h3>
              </header>
              <p className="text-airco2-home-subtitle grow">{t("home.compensarHuella")}</p>

            </div>
            {/* Image */}
            <div className="relative block group overflow-hidden md:w-1/2" to="/blog-post" data-aos="fade-down">
              <img className="w-full aspect-[16/9] md:aspect-[27/17] object-cover group-hover:scale-105 transition duration-700 ease-out" src={huye} width="540" height="340" alt="News 01" />
              <div className="absolute top-6 right-6">

              </div>
            </div>
            <div id='bannerMetodologia'/>
        
          </article>

        </div>

      </div>


    </section>
  )
}
