import CryptoJS from "crypto-js";
import Keycloak from "keycloak-js";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';


const MY_AUTH_APP = "MY_AUTH_APP"
const MY_AUTH_TOKEN = "MY_AUTH_TOKEN"
const MY_AUTH_TOKEN_R = "MY_AUTH_TOKEN_R"
const MY_AUTH_ENCRYPT_PASS = "passWord25874AirCo2419_"


export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
    const [isReady, setIsReady] = useState(false);

    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem(MY_AUTH_APP) ?? false);
    const [token, setToken] = useState(CryptoJS.AES.decrypt(localStorage.getItem(MY_AUTH_TOKEN) ?? "", MY_AUTH_ENCRYPT_PASS));
    const [tokenRefresh, setTokenResfresh] = useState(CryptoJS.AES.decrypt(localStorage.getItem(MY_AUTH_TOKEN_R) ?? "", MY_AUTH_ENCRYPT_PASS));

    const [keycloak, setKeycloak] = useState(new Keycloak('/keycloak.json'));


    useEffect(() => {
        async function initialize() {
            let tokenEnc = "";
            let  tokenRefreshEnc ="";
            try {
                tokenEnc = token.toString(CryptoJS.enc.Utf8);
                tokenRefreshEnc = tokenRefresh.toString(CryptoJS.enc.Utf8);
            } catch (error) {
                console.error(error)
                logout();
            }
            keycloak.init({
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                checkLoginIframe: false, // para bug en Firefox: evita que se pierda la sesión al navegar
                pkceMethod: 'S256',
                // promiseType: 'native',
                token: tokenEnc,
                refreshToken: tokenRefreshEnc
            })
                .then((authenticated) => {
                    if (!authenticated) {
                        logout();
                    } else {
                        login();
                    }
                    setIsReady(true)
                })
                .catch(console.error);
        }
        initialize();



    }, []);


    const setLogin = (token, tokenRefresh, urlRedirect) => {
        // console.log(urlRedirect)
        let url = "/";
        keycloak.init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            checkLoginIframe: false, // para bug en Firefox: evita que se pierda la sesión al navegar
            pkceMethod: 'S256',
            // promiseType: 'native',
            token: token,
            refreshToken: tokenRefresh
        })
            .then((authenticated) => {
                if (!authenticated) {
                    logout();
                } else {
                    login();
                    if (urlRedirect !== null && urlRedirect !== "/register") {
                        url = urlRedirect;
                    }
                    window.location.href = url;

                }
                setIsReady(true)
            })
            .catch(console.error);
    }

    const login = useCallback(function () {
        if (keycloak.tokenParsed?.usertype === "Propietario Forestal") {
            logout();
            return;
        }
        //debugger;
        localStorage.setItem(MY_AUTH_APP, true);
        localStorage.setItem(MY_AUTH_TOKEN, CryptoJS.AES.encrypt(keycloak.token, MY_AUTH_ENCRYPT_PASS));
        localStorage.setItem(MY_AUTH_TOKEN_R, CryptoJS.AES.encrypt(keycloak.refreshToken, MY_AUTH_ENCRYPT_PASS));

        setIsAuthenticated(true);
        setToken(keycloak.token)
        setTokenResfresh(keycloak.refreshToken)
    }, []);

    const logout = useCallback(function () {
        //debugger;
        localStorage.removeItem(MY_AUTH_APP);
        localStorage.removeItem(MY_AUTH_TOKEN);
        localStorage.removeItem(MY_AUTH_TOKEN_R);
        localStorage.removeItem('filtro')

        setIsAuthenticated(false);
        setToken("")
        setTokenResfresh("")
    }, []);


    const doLogin = useCallback(function (query) {
        window.location.href = "/login" + query
    }, []);

    const doLogout = useCallback(function () {
        keycloak.logout();
        logout();
    }, []);

    const doRegistration = useCallback(function () {
        keycloak.register();
    }, []);


    const isLoggedIn = () => !!keycloak.token;
    const getUsername = () => keycloak.tokenParsed?.preferred_username;
    const doLoginIdioma = () => keycloak.login();
    const getName = () => keycloak.tokenParsed?.given_name + " " + keycloak.tokenParsed?.family_name;
    const getToken = () => keycloak.token;
    const getCompanyName = () => keycloak.tokenParsed?.companyName;;

    const getTokenParsed = () => keycloak.tokenParsed;
    const value = useMemo(() => ({
        login,
        logout,
        doLogin,
        doLogout,
        doRegistration,
        doLoginIdioma,
        isLoggedIn,
        getUsername,
        getToken,
        setLogin,
        getTokenParsed,
        getName,
        isAuthenticated,
        isReady,
        getCompanyName,
    }), [login, logout, doLogin, isLoggedIn, doLogout, doRegistration, doLoginIdioma, getUsername, getToken, setLogin, getTokenParsed, getName, isAuthenticated, isReady, getCompanyName]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>

}

AuthContextProvider.propTypes = {
    children: PropTypes.object
}

export function useAuthContext() {
    return useContext(AuthContext);
}