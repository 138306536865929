import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '../context/authContext';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'


export default function FormAdress({
  formData,
  actualizar,
  modalACt,
  show

}) {

  const auth = useAuthContext();
  const { t } = useTranslation();


  const [location, setLocation] = useState({
    locationData: [],
    isFetching: false,
    error: false,
  });


  const [valueCountry, setValueCountry] = useState("");
  const [valueRegion, setValueRegion] = useState("");
  const [valueState, setValueState] = useState("");
  const [valueCity, setValueCity] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setstateName] = useState("");
  const [regionName, setregionName] = useState("");
  const [countryName, setcountryName] = useState("");


  const [countries, setCoutries] = useState([])
  const [filteredRegions, setFilteredRegions] = useState([])
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        await axios.get(`${process.env.REACT_APP_MP_ADDRESS_LOCATIONS}`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        }).then((response) => {
          const data = response.data.sort((a, b) => a.name - b.name)
          setLocation({ locationData: data, isFetching: true });
          setCoutries(data)

        });

      } catch (exception) {
        setLocation({ locationData: location.locationData, isFetching: true, error: true });
      }
    };
    fetchLocation();
  }, []);

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setValueCountry(formData?.countryId)
    } else {
      setValueCountry("");
    }
  }, [formData]);


  //Actualizar lista de regiones por Country seleccionada
  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setValueRegion(formData?.regionId);
    } else {
      setValueRegion("")
    }

    if (countries.length > 0 && valueCountry !== "") {
      let regionsOfCountry = countries.find(c =>
        c.id === Number(valueCountry)).regionList.sort(function (a, b) {
          return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        });
      setFilteredRegions(regionsOfCountry)
    }
  }, [valueCountry]);


  //Actualizar lista de estados por la región seleccionada
  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setValueState(formData?.stateId);

    } else {
      setValueState("");
    }
    if (filteredRegions.length > 0 && valueRegion !== "") {
      const statesOfRegions = filteredRegions.find(c =>
        c.id === Number(valueRegion)).stateList.sort(function (a, b) {
          return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        });
      setFilteredStates(statesOfRegions);
      const stateId = statesOfRegions.filter(c =>
        c.id === Number(formData?.stateId)
      )

      if (stateId.length === 0) {
        setValueState("")
      }

    }
    ;
  }, [valueRegion]);


  //Actualizar lista de ciudades por el estado seleccionado
  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setValueCity(formData?.cityId);
    } else {
      setValueCity("")
    }
    if (filteredStates.length > 0 && valueState !== "") {
      const citiesOfState = filteredStates.find(c =>
        c.id === Number(valueState))
      if (citiesOfState && citiesOfState.citiesList) {
        const sortedCitiesOfState = citiesOfState.citiesList.sort(function (a, b) {
          return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
        });
        setFilteredCities(sortedCitiesOfState);

      }

    }
  }, [valueState]);



  const handleCheckCountry = (event) => {
    setValueCountry(event.target.value);
    setcountryName(event.target.options[event.target.selectedIndex].getAttribute("data-country"));
  }

  const handleCheckRegion = (event) => {
    setValueRegion(event.target.value);
    setregionName(event.target.options[event.target.selectedIndex].getAttribute("data-region"));
  }

  const handleCheckState = (event) => {
    setValueState(event.target.value)
    setstateName(event.target.options[event.target.selectedIndex].getAttribute("data-state"));
  }

  const handleCheckCity = (event) => {
    setValueCity(event.target.value)
    setCityName(event.target.options[event.target.selectedIndex].getAttribute("data-city"));

  }

  const handlerUpdate = (v) => {
    actualizar(v)
  }

  const handleClose = (v) => {
    modalACt(v);
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      "id": e.target.id.value,
      "street": e.target.street.value,
      "pc": e.target.cp.value,
      "cityId": e.target.city.value,
      "city": cityName,
      "countryId": e.target.country.value,
      "country": countryName,
      "creationDate": new Date(),
      "nameAddress": e.target.name.value,
      "person": e.target.surname.value,
      "principal": e.target.principal.checked,
      "regionId": e.target.region.value,
      "region": regionName,
      "stateId": e.target.state.value,
      "state": stateName,
      "cif": e.target.cif.value,
      "updatedDate": e.target.id.value !== undefined ? new Date() : null
    }


    const fetchUpdateAddress = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_MP_ADDRESS}`, data,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${auth.getToken()}`
            }
          })
          .then((response) => {
            handlerUpdate(response.data);

          }, (error) => {
            console.log(error);
          });
      } catch (exception) {
        console.log(exception);
      }
    };
    fetchUpdateAddress();
    handleClose(false)

  }






  return (
    <Modal className='mt-5'
      show={show}
      centered>
      {(location !== undefined && location.locationData !== undefined && location.locationData.length > 0) &&
        <div>
          <Modal.Header className='text-airco2-filter-content'>
            <Modal.Title>{t("orders.direccion")}</Modal.Title>
            <button className='font-bold' onClick={() => handleClose(false)}>
              x
            </button>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="space-y-4 mt-3 ml-3 mr-3">
                <input hidden id="id" defaultValue={formData?.id} />

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label className="block text-airco2-filter-content  text-sm font-medium mb-1" htmlFor="name">{t("orders.nombreAddress")}<span className="text-airco2-secondary">*</span></label>
                    <input id="name" required className="form-user-data w-full" type="text"
                      placeholder={t("orders.placeholderAddress")}
                      defaultValue={formData?.nameAddress} />
                  </div>


                </div>

                <div className="flex space-x-4">

                  <div className="flex-1">
                    <label className="block text-airco2-filter-content text-sm font-medium mb-1" htmlFor="surname">{t("orders.nameCompletoAddress")}<span className="text-airco2-secondary">*</span></label>
                    <input id="surname" required className="form-user-data w-full" type="text"
                      placeholder={t("orders.placeholderCompleto")}
                      defaultValue={formData?.person} />
                  </div>


                  <div className="flex-1">
                    <label className="block text-airco2-filter-content text-sm font-medium mb-1" htmlFor="surname">{t("orders.cif")}<span className="text-airco2-secondary">*</span></label>
                    <input id="cif" required className="form-user-data w-full" type="text"
                      placeholder={t("orders.cif")}
                      defaultValue={formData?.cif} />
                  </div>

                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label className="block text-airco2-filter-content text-sm font-medium mb-1" htmlFor="street">{t("orders.calle")} <span className="text-airco2-secondary">*</span></label>
                    <input id="street" required className="form-user-data w-full" type="text"
                      placeholder={t("orders.placeholderCalle")}
                      defaultValue={formData?.street} />
                  </div>

                  <div className="flex-1">
                    <label className="block text-airco2-filter-content text-sm font-medium mb-1" htmlFor="cp">{t("orders.codigoPostal")}<span className="text-airco2-secondary">*</span></label>
                    <input id="cp" required className="form-user-data w-full" type="text"
                      placeholder={t("orders.codigoPostal")}
                      defaultValue={formData?.pc} />
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label className="block text-airco2-filter-content text-sm font-medium mb-1" htmlFor="country">{t("orders.pais")}<span className="text-airco2-secondary">*</span></label>
                    <select required
                      value={valueCountry}
                      onChange={(e) => handleCheckCountry(e)}
                      name="country" id="country" className="form-user-data w-full">
                      <option value="" disabled={valueCountry !== ""}>{t("orders.placeholderLocation")}</option>
                      {(countries) !== 0 &&
                        countries.map((x, key) =>
                          <option key={key} data-country={x.name} value={`${x.id}`}>{x.name}</option>
                        )}
                    </select>
                  </div>

                  <div className="flex-1">
                    <label className="block text-airco2-filter-content text-sm font-medium mb-1" htmlFor="region">{t("orders.region")} <span className="text-airco2-secondary">*</span></label>
                    <select required
                      value={valueRegion}
                      onChange={(e) => handleCheckRegion(e)}
                      name="region" id="region" className="form-user-data w-full">
                      <option value="" disabled={valueRegion !== ""}>{t("orders.placeholderLocation")}</option>
                      {(filteredRegions.length) !== 0 &&
                        filteredRegions.map((x, key) =>
                          <option data-region={x.name} key={key} value={`${x.id}`}>{x.name}</option>
                        )}
                    </select>
                  </div>
                </div>

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <label className="block text-airco2-filter-content text-sm font-medium mb-1" htmlFor="state">{t("orders.estado")}<span className="text-airco2-secondary">*</span></label>
                    <select required
                      value={valueState}
                      onChange={(e) => handleCheckState(e)}
                      name="state" id="state" className="form-user-data w-full">
                      <option value="" disabled={valueState !== ""}>{t("orders.placeholderLocation")}</option>
                      {(filteredStates.length !== 0) &&
                        // [{ id: "", name: "" }, ...filteredStates].map((y, key) =>
                        filteredStates.map((y, key) =>
                          <option data-state={y.name} key={key} value={y.id}>{y.name}</option>
                        )}
                    </select>
                  </div>



                  <div className="flex-1">
                    <label className="block text-airco2-filter-content text-sm font-medium mb-1" htmlFor="city">{t("orders.ciudad")}<span className="text-airco2-secondary">*</span></label>
                    <select required
                      value={valueCity}
                      onChange={(e) => handleCheckCity(e)}
                      name="city" id="city" className="form-user-data w-full">
                      <option
                        value=""
                        disabled={valueCity !== ""}>{t("orders.placeholderLocation")}</option>
                      {(filteredCities !== "" || filteredCities.length !== 0) &&
                        filteredCities.map((y, key) =>
                          <option data-city={y.name} key={key} value={y.id}>{y.name}</option>
                        )}
                    </select>
                  </div>
                </div>
                <div className="flex space-x-4">

                  <div className="flex-1">
                    <label className="flex items-center">
                      <input id="principal" type="checkbox" className="form-checkbox" defaultChecked={formData?.principal} />
                      <span className="text-sm ml-2"> {t("orders.principal")} </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-3 ml-3 mr-3">
                <div className="mb-2">
                  <button className="btn bg-airco2-principal w-full text-white" type="submit">{t("orders.guardar")}</button>
                </div>

              </div>
            </form>
          </Modal.Body>

        </div>

      }
    </Modal>
  )
}
