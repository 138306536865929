import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import arbol from '../images/arbol.jpeg';
import category from '../images/categoryIconVerde.svg';
import circle from '../images/location.svg';
import forest from '../images/iconTrees.svg';
import forestT from '../images/iconTreesVerde.svg';
import owner from '../images/userProvider.svg';
import { handleClick } from './Utils';

import { providerUrl } from "./Utils";

export default function CardCarrito({ categories,
  products,
  // selectedItems,
  // currentpage

}) {




  function getColor(name) {
    return categories.categoriesData.find(element => element.name === name)?.color;
  }
 
 

  return (

    <Card className=" border-none card-carrito text-airco2-general" style={{ maxWidth: '450px' }}  >
      <div className='container-img-shop' style={{ maxHeight: '180px' }}>

        <div className=''>

          {products.img.length === 0 ?
            <div className='img-fondo-shop' style={{ backgroundImage: `url(${arbol})` }} >

            </div>
            :

            products.img.map((y, index) =>

              (y.principal) === true &&
              <div className='img-fondo-shop' key={index} style={{ backgroundImage: `url(${y.path})` }}>

              </div>




            )}


        </div>
        <div className="container-forestype absolute" style={{ right: '-150px', bottom:'-4px' }}>
          <div className=" inline-flex items-center text-xs text-white bg-airco2-principal   text-center px-2 py-0.5">
            <img alt='arbol-icono' src={forest} width={'22px'} className='mx-1'></img>
            <span className='text-xs' data-tip data-for="registerTip">{products.forestType}</span>
          </div>
        </div>
        {(products.category !==null)&&
          <div className=" container-category absolute" style={{ left: '0px', top: '0px' }}>
            <div className={` inline-flex items-center text-xs text-white bg-opacity-40 text-center px-2 py-0.5 category-card-catalogo`} style={{ backgroundColor: `${getColor(products.category)}` }}>
            <svg className=" inline-block mb-1" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3" />
              </svg>
              <span className='text-xs' data-tip data-for="registerTip">{products.category}</span>
            </div>
          </div>
        }
      </div>

      <Card.Body>
  
        <Card.Title className='font-bold flex'>
          <span className='mx-2 text-xs'>{products.name}</span>
        </Card.Title>


        <Card.Text>
          <span className='flex'>
            <img alt='circulo-icono' src={circle} width={'12px'} ></img>
            <a href={`https://maps.google.com/?q=${products.latitude},${products.longitude}`} aria-label='redirige a googleMaps' target='_blank' rel='noopener noreferrer' className='text-airco2-general'>
              <span className='mx-2 text-xs'> {products.city}, {products.state}</span>

            </a>
          </span>

          <Link onClick={handleClick}  to={providerUrl(products)} className='text-airco2-general'>
            <span className='flex'>
              <img alt='circulo-icono' src={owner} width={'12px'} ></img>
              <span className='mx-2 text-xs'>{products.provider}</span>
            </span>
          </Link>
        
          <span className='category-forestype'>
            {(products.category !==null)&&
              <span className='flex'>
              <img alt='circulo-icono' src={category} width={'12px'} ></img>
              <span className=' category-min mx-2 text-xs'> {products.category}</span>
            </span>
            }
            
            <span className='flex'>
              <img alt='circulo-icono' src={forestT} width={'12px'} ></img>
              <span className=' forestype-min mx-2 text-xs'>{products.forestType}</span>
            </span>
          </span>
        </Card.Text>
      </Card.Body>
    </Card >
  )
}





