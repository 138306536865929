import React from 'react'
import pic from '../images/forest.png'
import { useTranslation } from 'react-i18next';



export default function BannerForest() {
  const { t } = useTranslation();
  const param='forestal'
  return (

    <div className="w-full h-[32rem] sm:h-96 flex items-center text-center"
      style={{ backgroundImage: `url(${pic})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className='w-full  container '>
        <h2 className='text-airco2-home-titleSlider' data-aos="fade-down">  {t("home.monteWeb")}
        </h2>

        <h3 className='text-airco2-home-titleSlider' data-aos="fade-down">  {t("home.monteWebForestales")}
        </h3>
        <h3 className='text-airco2-home-titleSlider' data-aos="fade-down">  {t("home.monteWebForestalesb")}
        </h3>


        <div className="mt-8" data-aos="fade-down" data-aos-delay="300">
          <a target={"_blank"} href={`https://www.airco2.earth/register?param=${param}`} >
            <button className='bg-airco2-principal w-60 pt-1 pb-1 '>
              {t("home.web")}
            </button>

          </a>

        </div>
      </div>


    </div>
  )
}
