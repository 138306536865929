import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from '../context/authContext';
import { useEffect } from "react";

export default function RenderOnAuthenticated() {
    const auth = useAuthContext();

    useEffect(() => {
        if (!auth.isLoggedIn()) {
            const query ="?originUrl=" + window.location.pathname;
            auth.doLogin(query);
        }
    }, []);


    return (
        <div>
            <Outlet />
        </div>
    )



}