import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../context/authContext';
import AddressItem from './AddressItem';
import FormAdress from '../../FormAdress';

function AddressList() {
    const { t } = useTranslation();
    const auth = useAuthContext();

    const [addressList, setAddressList] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchAddress = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_MP_ADDRESS}`, {

                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${auth.getToken()}`
                    }
                });
                setAddressList({ data: response.data, isFetching: true });

            } catch (exception) {
                console.log(exception);
                setAddressList({ data: [], isFetching: true });
            }
        };
        fetchAddress();

    }, [auth]);


    const [show, setShow] = useState(false);
    const [addresToEdit, setAddresToEdit] = useState({})

    const actualizar = (v) => {
        const element = addressList.data.find(f => f.id === v.id);
        if (element !== undefined) {
            const newList = addressList.data.map(m => {
                if (m.id === v.id) {
                    return v;
                } else {
                    return m;
                }
            })
            setAddressList({ data: newList, isFetching: true });


        } else {
            setAddressList({ data: [...addressList.data, v], isFetching: true });
        }
        setAddresToEdit({})

    }

    const modalACt = (v) => {
        setAddresToEdit({})
        setShow(v)
    }


    const getAddresToEdit = (address) => {
        setAddresToEdit(address)
        setShow(true)
    }
    

    //cambiar a principal false cuando se cambia la principal 
    const dirPrincipal = addressList.data.filter((dir) => dir.principal).sort((a,b)=>b.updatedDate.localeCompare(a.updatedDate))
    const idDirPrincipal = dirPrincipal[0]
    const noPrincipal = addressList.data.filter((f) => f.id !==idDirPrincipal.id )

    useEffect(() => {
        setAddressList(prevAddressList => ({
            ...prevAddressList,
            data: prevAddressList.data.map(address => {
              if (noPrincipal.some(updatedAddress => updatedAddress.id === address.id)) {
                return { ...address, principal: false };
              }
              return address;
            })
          }));     

    }, [idDirPrincipal]);


    return (< div className="grow " >

        <div className="p-6 space-y-6">
            <div className='flex flex-col md:flex-row justify-between'>
                <h2 className="text-2xl text-airco2-filter-content  mb-5">{t("account.address.myaddress")}</h2>

                <span className='text-right'>
                    <button className="btn bg-airco2-principal   text-white" onClick={() => setShow(true)}>{t("account.address.new")}</button>
                </span>
            </div>


            <section>

                {!addressList.isFetching && <div className="text-center">
                    <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-principal" role="status">
                        <span className="visually-hidden">{t("account.address.getAddress")}</span>
                    </div>
                    <div>
                        {t("account.address.getAddress")}
                    </div>
                </div>}

                {(addressList.isFetching && addressList.data.length === 0) &&
                    t("account.address.noAddress")
                }


                {(addressList.isFetching && addressList.data.length > 0) &&
                    <div className="grid gap-4 grid-cols-1  md:grid-cols-2 lg:grid-cols-3">
                        {addressList.data.map(a => { return <AddressItem key={a.id} address={a} setAddresToEdit={getAddresToEdit}></AddressItem> })}
                    </div>
                }

            </section>
        </div>

        <FormAdress
            formData={addresToEdit}
            actualizar={actualizar}
            modalACt={modalACt}
            show={show}

        />


    </div>)
}

export default AddressList;
