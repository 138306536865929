import { createContext, useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import PropTypes from 'prop-types';

export const CountriesContext = createContext();
export function CountriesContextProvider({ children }) {

    const [countries, setCountries] = useState([]);


    useEffect(() => {
        const fetchcountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_MP_LOCATIONS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                });
                setCountries(response.data);

            } catch (exception) {
            }
        };
        fetchcountries();
    }, []);


    const value = useMemo(() => ({
        countries,
    }), [countries]);


    return <CountriesContext.Provider value={value}>
        {children}
    </CountriesContext.Provider>

}

CountriesContextProvider.propTypes = {
    children: PropTypes.object
}

export function useCountriesContext() {
    return useContext(CountriesContext);
}