import React from 'react';
import { formatThousands } from '../utils/Utils';
import arbol from '../images/arbolDetail.jpg';


function CartItems({

  shoppingPay,

}) {


  return (
    <>
      {(
        shoppingPay === undefined || shoppingPay === null | shoppingPay.orderLineList.length === 0)

        ?
        "cargando"
        :


        shoppingPay.orderLineList.map((x, key) =>
          <ul key={key} >

            {/* Cart item */}
            <li className="sm:flex items-center py-6 border-b border-gray-200">
              <a className="block mb-4 sm:mb-0 mr-5 md:w-32 xl:w-auto shrink-0" href="#0">
                {
                  x.product.productImageDtoList.length === 0 ?
                    <img className="rounded-sm" src={arbol} width="100" height="142" alt="Product 02" />
                    :
                    x.product.productImageDtoList.map((y, index) =>
                      (y.principal) &&
                      <img key={index} className="rounded-sm" src={y.path} width="100" height="142" alt="Product 02" />

                    )}
              </a>
              <div className="grow">
                <div className='flex  justify-between items-center'>
                  <a href="#0">
                    <h3 className="text-sm font-semibold text-gray-800 ">{x.product.name}</h3>

                  </a>

                  <span className="text-sm underline hover:no-underline">{formatThousands((x.unitPrice * x.quantity).toFixed(2))} €</span>

                </div>
               
                {/* <h3 className="text-xs  text-gray-800 mb-1">{x.product.state}, {x.product.city}</h3>
              <h3 className="text-xs  text-gray-800 mb-1">{x.product.provider}</h3> */}
                <h3 className="text-xs  text-gray-800 mb-1">{x.quantity} t x {x.unitPrice} €</h3>


              </div>

            </li>

          </ul>
        )}

      {/* <div className="mt-6">
        <a className="text-sm font-medium text-indigo-500 hover:text-indigo-600" href="#0">&lt;- Back To Shopping</a>
      </div> */}
    </>
  );
}

export default CartItems;