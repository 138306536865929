function PaginaEstatica({
    imagen,
    title,
    // texto,
    children

}) {
    return (<>
        <div className="relative h-80 bg-gray-200">
            <div
                className="d-block w-100 h-80"
                style={{ backgroundImage: `url(${imagen})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="flex justify-center items-center m-auto h-full text-3xl font-bold text-gray-200 pt-5" style={{ textShadow: "1px 1px 2px #000000" }}>{title}</div>
            </div>
        </div>
        {children}
        {/* <div className="container mt-4 mb-4 p-5"   dangerouslySetInnerHTML={{__html: texto}}>
        </div> */}
    </>)
}

export default PaginaEstatica