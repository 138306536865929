import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function FiltroCatalogo({
  applyFilter,
  categoriesList,
  speciesList,
  countriesList,
  developmentList,
  regionList,
  estadosList,
  cityList,
  locationsC,

  setShowCatalogue,
  showFilter,
  setShowFilter,

  actualizar,

}) {
  const { t } = useTranslation();
  const currentTime = new Date().getTime();
  const expirationTime = 60 * 60 * 24 * 1000 * 2;
  const expiration = currentTime + expirationTime;
  const filtroData = JSON.parse(localStorage.getItem('filtro'));


  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;


  useEffect(() => {
    if (isMobile) {
      setShowFilter(true)
    } else {
      setShowFilterResponsive(true)
    }
  }, [isMobile]);


  const [showFilterResponsive, setShowFilterResponsive] = useState(false);

  const handlechangeShow = () => {
    setShowFilterResponsive(!showFilterResponsive)
    setShowCatalogue(showFilterResponsive)
    // handlerUpdate(showResponsive)
  }








  //Actualiar el check del filtro de categoriass:
  const [checkedCat, setCheckedCat] = useState(filtroData?.data[0].filter(item => item.check).map(item => item.category) || []);
  const handleCheckcat = (event) => {
    var updatedList = [...checkedCat];
    if (event.target.checked) {
      updatedList = [...checkedCat, event.target.value];
    } else {
      updatedList.splice(checkedCat.indexOf(event.target.value), 1);
    }
    setCheckedCat(updatedList);
  };

  //Actualiar el check del filtro de especies:
  const [checkedForest, setCheckedForest] = useState(filtroData?.data[1].filter(item => item.check).map(item => item.forestType) || []);
  const handleCheckForest = (event) => {
    var updatedList = [...checkedForest];
    if (event.target.checked) {
      updatedList = [...checkedForest, event.target.value];
    } else {
      updatedList.splice(checkedForest.indexOf(event.target.value), 1);
    }
    setCheckedForest(updatedList);
  };


  //Actualiar el check del filtro de paises:
  const [checkedCountry, setCheckedCountry] = useState(filtroData?.data[2].filter(item => item.check).map(item => item.country) || []);


  //Actualiar el check del filtro de obj desarrollos:
  const [checkedDevelopment, setCheckedDev] = useState(filtroData?.data[3].filter(item => item.check).map(item => item.susDev) || []);
  const handleCheckDev = (event) => {
    var updatedList = [...checkedDevelopment];
    if (event.target.checked) {
      updatedList = [...checkedDevelopment, event.target.value];
    } else {
      updatedList.splice(checkedDevelopment.indexOf(event.target.value), 1);
    }
    setCheckedDev(updatedList);
  };

  // //Actualiar el check del filtro de regiones:

  const [checkedRegion, setCheckedRegion] = useState(filtroData?.data[4].filter(item => item.check).map(item => item.region) || []);



  const handleCheckRegion = (event) => {
    setCheckedCity([]);
    setCheckedState([]);
    setValueCity("");
    setValueState("");
    localStorage.removeItem('filtro');

    var updatedList = [...checkedRegion];
    if (event.target.value !== undefined) {
      updatedList = [event.target.value];
      setValueRegion(event.target.value)

    } else {
      updatedList.splice(checkedRegion.indexOf(event.target.value), 1);

    }
    setCheckedRegion(updatedList);

  };


  //Actualiar el check del filtro de estados:
  const [checkedState, setCheckedState] = useState(filtroData?.data[5].filter(item => item.check).map(item => item.state) || []);
  const handleCheckState = (event) => {
    setCheckedCity([]);
    setValueCity("");
    var updatedList = [...checkedState];
    if (event.target.value !== undefined) {
      updatedList = [event.target.value];
      setValueState(event.target.value);

    } else {
      updatedList.splice(checkedState.indexOf(event.target.value), 1);

    }
    setCheckedState(updatedList);
  };


  //Actualiar el check del filtro de ciudades:
  const [checkedCity, setCheckedCity] = useState(filtroData?.data[6].filter(item => item.check).map(item => item.city) || []);
  const handleCheckCity = (event) => {
    var updatedList = [...checkedCity];
    if (event.target.value !== undefined) {

      updatedList = [event.target.value];
      setValueCity(event.target.value)
    } else {
      updatedList.splice(checkedCity.indexOf(event.target.value), 1);
    }

    setCheckedCity(updatedList);

  };



  //Aplicar cambios
  const handleApply = () => {
    const newState = new Array(7);

    newState[0] = categoriesList.map(obj => {
      if (checkedCat.some(v => (v === obj.category))) {
        return { ...obj, check: true };
      }
      return { ...obj, check: false };
    });


    newState[1] = speciesList.map(obj => {
      if (checkedForest.some(v => (v === obj.forestType))) {
        return { ...obj, check: true };
      }
      return { ...obj, check: false };
    });

    newState[2] = countriesList.map(obj => {
      if (checkedCountry.some(v => (v === obj.country))) {
        return { ...obj, check: true };
      }
      return { ...obj, check: false };
    });

    newState[3] = developmentList.map(obj => {
      if (checkedDevelopment.some(v => (v === obj.susDev))) {
        return { ...obj, check: true };
      }
      return { ...obj, check: false };
    });


    newState[4] = regionList.map(obj => {
      if (checkedRegion.some(v => (v === obj.region))) {
        return { ...obj, check: true };
      }
      return { ...obj, check: false };
    });


    newState[5] = estadosList.map(obj => {
      if (checkedState.some(v => (v === obj.state))) {
        return { ...obj, check: true };
      }
      return { ...obj, check: false };
    });

    newState[6] = cityList.map(obj => {
      if (checkedCity.some(v => (v === obj.city))) {
        return { ...obj, check: true };
      }
      return { ...obj, check: false };
    });

    applyFilter(newState);
    localStorage.setItem('filtro', JSON.stringify({ data: newState, expiration: expiration }));

    if (isMobile) {
      handlechangeShow()
    }
  };










  useEffect(() => {
    const expiredLocal = localStorage.getItem('filtro');
    if (expiredLocal) {
      const parsedData = JSON.parse(expiredLocal);
      const { data, expiration } = parsedData;

      if (new Date().getTime() < expiration) {

        // console.log(data);
      } else {
        localStorage.removeItem('filtro');
      }
    } else {

      // console.log('El dato no existe');
    }

  }, []);

  // Borrar filtros seleccionados
  const handleClear = () => {
    const newState = new Array(7);

    newState[0] = categoriesList.map(obj => {
      return { ...obj, check: false };
    });
    setCheckedCat([]);

    newState[1] = speciesList.map(obj => {
      return { ...obj, check: false }
    });
    setCheckedForest([]);

    newState[2] = countriesList.map(obj => {
      return { ...obj, check: false }
    });
    setCheckedCountry([]);

    newState[3] = developmentList.map(obj => {
      return { ...obj, check: false }
    });
    setCheckedDev([]);

    newState[4] = regionList.map(obj => {
      return { ...obj, check: false }
    });
    setCheckedRegion([]);

    newState[5] = estadosList.map(obj => {
      return { ...obj, check: false }
    });
    setCheckedState([]);


    newState[6] = cityList.map(obj => {
      return { ...obj, check: false }
    });
    setCheckedCity([]);

    [categoriesList, speciesList, countriesList, developmentList, regionList, estadosList, cityList] = newState;
    document.querySelectorAll('input[type=checkbox]').forEach(function (checkElement) {
      checkElement.checked = false;
      applyFilter(newState);
      setValueCountry("");
      setValueRegion("");
      setValueState("")
      setValueCity("");
    });
    localStorage.removeItem('filtro')

    if (isMobile) {
      handlechangeShow()
    }
  };



  // actualizar el valor de los inputs select
  const [valueCountry, setValueCountry] = useState(filtroData?.data ? filtroData.data[2][0].country : "");


  const [valueRegion, setValueRegion] = useState(filtroData?.data ? checkedRegion.join("") : "");
  const [valueState, setValueState] = useState(filtroData?.data ? checkedState.join("") : "");
  const [valueCity, setValueCity] = useState(filtroData?.data ? checkedCity.join("") : "");


  const stados = locationsC.map((item, index) =>
    item.regionList.map((x, key) =>
      x.stateList.map((y) => y)))

  const stadoFilter = stados.map((item, index) =>
    item.map((y) =>
      y.filter((c) => c.name === valueState)))



  const regions = locationsC.map((item, index) =>
    item.regionList.map((x,) => x))

  const regionsFilter = regions.map((item, index) =>
    item.filter
      ((c) => c.name === valueRegion))



  const sortedLocations = locationsC.sort((a, b) => a.name.localeCompare(b.name));


  const classFilter = showFilter ? ' min-w-60' : 'w-full'





  return (
    <div style={{ minWidth: '12rem' }} className={`text-airco2-general ${!showFilter && "hidden"} `} >
      <div className={`${classFilter}`}>
        <div className={` px-3`}>
          <button className='md:hidden text-airco2-filter-title text-base mb-3 flex' onClick={() => handlechangeShow()}>
            {t("filterCat.filtros")}
            <span className={`block md:hidden ${showFilterResponsive ? "rotate-180" : ""}`} >
              <svg className="w-6 h-6 rounded fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </span>
          </button>

          <button className='xs:hidden flecha-filter-pc text-airco2-filter-title text-base mb-3 flex'>
            {t("filterCat.filtros")}
          </button>


          <div>
            <div className={`form mb-5 relative  ${showFilterResponsive ? "block" : "hidden "}  `} >
              <div className={`grid grow md:grid-cols-4 xl:grid-cols-1 gap-6 mb-2`} >
                {/* Group 1 */}
                <div>
                  <div className=" text-airco2-filter-title mb-1 flex" >
                    {t("filterCat.localizacion")}
                  </div>
                  <ul className="space-y-2">
                    <li className="py-1" >
                      <select value={valueCountry} onChange={(event) => setValueCountry(event.target.value)} id="country" className="border-principal   bg-white text-airco2-filter-content rounded-lg block w-full p-2.5">
                        <option >{t("filterCat.chooseOp")}</option>
                        {
                          sortedLocations.map((item, index) =>
                            <option key={index} value={`${item.name}`} >{item.name}</option>
                          )
                        }

                      </select>
                    </li>

                    {(valueCountry === "Elige una opción" || valueCountry === '') && !filtroData?.data ? (
                      ""
                    ) : (

                      <li className="py-1" >

                        <select value={valueRegion} onChange={handleCheckRegion} name="region" id="region" className="border-principal   bg-white text-airco2-filter-content rounded-lg  block w-full p-2.5  ">
                          <option>    {t("filterCat.chooseOp")}</option>
                          {
                            locationsC.map((item, index) =>
                              item.regionList
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((x, key) =>
                                  <option key={key} value={`${x.name}`}>{x.name}</option>
                                )
                            )
                          }
                        </select>
                      </li>
                    )}
                    {(valueRegion === '' || valueRegion === "Elige una opción") && !filtroData?.data ? (
                      ""
                    ) : (


                      <li className="py-1 ">
                        <select value={valueState} onChange={handleCheckState} id="state" className="border-principal   bg-white text-airco2-filter-content rounded-lg  block w-full p-2.5  ">
                          <option >    {t("filterCat.chooseOp")}</option>

                          {
                            regionsFilter.map((item, index) =>
                              item.map((x, key) =>
                                x.stateList
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((y, keyY) =>
                                    <option key={keyY} value={`${y.name}`}>{y.name}</option>
                                  )
                              )
                            )
                          }

                        </select>
                      </li>
                    )}

                    {(valueState === '' || valueState === "Elige una opción") && !filtroData?.data ? (
                      ""
                    ) : (


                      <li className="py-1 " >
                        <select value={valueCity} onChange={handleCheckCity} id="city" className="border-principal   bg-white text-airco2-filter-content rounded-lg  block w-full p-2.5  ">
                          <option >    {t("filterCat.chooseOp")}</option>
                          {
                            stadoFilter.map((item, index) =>
                              item.map((v) =>
                                v.map((x) =>
                                  x.citiesList
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((y, key) => (
                                      <option key={key} value={`${y.name}`}>
                                        {y.name}
                                      </option>
                                    ))
                                )
                              )
                            )
                          }

                        </select>
                      </li>
                    )}
                  </ul>

                </div>
                {/* Group 2 */}
                <div className='mb-2'>
                  <div className=" text-airco2-filter-title mb-1">
                    {t("filterCat.especie")}
                  </div>
                  <ul className="">
                    {speciesList.map((item, index) => (
                      <li key={index}>
                        <label className="">
                          <input value={item.forestType} type="checkbox"
                            onChange={handleCheckForest}
                            defaultChecked={item.check}
                          />
                          <span className="text-airco2-filter-content font-medium ">{item.forestType}</span>
                        </label>
                      </li>
                    ))}

                  </ul>
                </div>

                {/* Group 4 */}
                <div className='mb-2'>
                  <div className=" text-airco2-filter-title mb-1">  {t("filterCat.categoria")}</div>
                  <ul className="">
                    {categoriesList.map((item, index) => (
                      <li key={index}>
                        <label>
                          <input value={item.category} type="checkbox"
                            onChange={handleCheckcat}
                            defaultChecked={item.check}
                          />
                          <span className="text-airco2-filter-content font-medium">{item.category}</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* Group 5 */}
                <div className='mb-2'>
                  <div className=" text-airco2-filter-title mb-1"> {t("filterCat.desSostenible")}</div>
                  <ul style={{ minWidth: '250px' }}>
                    {developmentList.map((item, index) => (
                      <li key={index}>
                        <label>
                          <input value={item.susDev} type="checkbox"
                            onChange={handleCheckDev}
                            defaultChecked={item.check}
                          />
                          <span className="text-airco2-filter-content font-medium ">{item.susDev}</span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* Group 6 */}
              <div className='self-end text-airco2-filter-content  text-airco2-filter-title'>
                <ul className="flex justify-around flex-wrap gap-2">
                  <li>
                    <button type='submit' className='px-6 py-2 bg-airco2-principal text-airco2-filter-title text-white' onClick={handleApply}  >
                      {t("filterCat.aplicar")}  </button>
                  </li>

                  <li >
                    <button type='submit' className='px-6 py-2 bg-airco2-general text-airco2-filter-title text-white' onClick={handleClear}  >
                      {t("filterCat.borrar")}  </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  );
}

export default FiltroCatalogo;