import React from 'react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../context/authContext'
import ShoppingItem from '../components/ShoppingItem'
import ShoppingBannerSummary from '../components/ShoppingBannerSummary'
import { useShoppingContext } from '../context/shoppingContext';
import Contador from '../components/contador'
import { formatThousands } from './../utils/Utils';




export function Shopping() {
  const { t } = useTranslation();
  const auth = useAuthContext();
  const shoppingContext = useShoppingContext();

  let [envQ, updateEnv] = useState("");
  const actualizar = (v) => {
    setShopping({ shoppingData: v, isFetching: false });
    shoppingContext.updateCart(v);
  }


  const [shopping, setShopping] = useState({
    shoppingData: [],
    isFetching: false
  });

  useEffect(() => {
    setShopping({ shoppingData: shoppingContext.cart, isFetching: true });
  }, [shoppingContext.cart]);

  useEffect(() => {
    if (shoppingContext.updateCartState) {
      setShopping({ shoppingData: shoppingContext.cart, isFetching: true });
      shoppingContext.setUpdateCartState(false);
    }

  }, [shoppingContext.updateCartState]);


  const data = shopping.shoppingData




  const handlerUpdate = (v) => {
    setShopping({ shoppingData: v, isFetching: false })
    shoppingContext.updateCart(v);
  }
  let quantity = [];
  if (shopping.shoppingData && shopping.shoppingData.orderLineList && shopping.shoppingData.orderLineList.length > 0) {
    quantity = shopping.shoppingData.orderLineList.map((x) => x.quantity);
  }


  let quantityTotal = quantity.reduce((a, b) => a + b, 0);
  let [quantityAct, setQuantity] = useState(quantity)
  let [quantityTotalAct, setQuantityTotal] = useState(quantityTotal)


  useEffect(() => {
    setQuantity(quantityAct)
    setQuantityTotal(quantityTotalAct)



  }, [envQ])


  const expiredDate = data.expiredDate

  return (

    <div className='text-airco2-general'>

      <div className='flex flex-column mx-6'>

        <p className=' mt-6 text-md '>
          {t("shop.condicionesCreditos")}


        </p>

        <div className='mt-1'>
          {t("shop.informacionOk")}.  {t("shop.compraNombre")}

          <span className='italic' > {auth.getUsername()} </span>
          <button className='hover:underline font-semibold' onClick={() => auth.doLogout()}> ({t("shop.logout")})</button>

        </div>


      </div>

      {(quantity > 0) &&
        <div className='flex justify-center md:justify-end items-center flex-wrap mt-6 text-airco2-general'>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alarm" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#F19B7F" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="13" r="7" />
              <polyline points="12 10 12 13 14 13" />
              <line x1="7" y1="4" x2="4.25" y2="6" />
              <line x1="17" y1="4" x2="19.75" y2="6" />
            </svg>
          </span>

          <span className='mt-1'>
            <Contador
              expiredDate={expiredDate}
              contador={'contador'}
            // separacion={'separacion'}
            // gap={'gap-1'}
            />

          </span>


        </div>
      }




      {/* Table */}
      <div className="overflow-x-auto mt-4 mx-1">
        <table className="table-auto w-full divide-y divide-gray-200">
          {/* Table header */}
          <thead className="text-xs text-airco2-general  border-t border-gray-200">
            <tr className='flex'>

              <th className="px-2 py-3 first:pl-5 last:pr-5 td-imagen w-60 md:w-[16rem] lg:w-[24rem] xl:w-[28rem] 2xl:w-[30rem]">
                <div className="font-semibold pl-5">{t("shop.producto")}</div>

              </th>


              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52">
                <div className="font-semibold text-center">{t("shop.precio")}</div>
              </th>

              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52">
                <div className="font-semibold text-center">{t("shop.creditos")}</div>
              </th>

              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52">
                <div className="font-semibold text-center">{t("shop.total")}</div>
              </th>

              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52">
                <div className="font-semibold text-center">{t("shop.eliminar")}</div>
              </th>

            </tr>
          </thead>


          <tbody className="text-sm text-airco2-general">


            {shopping.shoppingData.orderLineList === undefined || shopping.shoppingData.orderLineList === null ?
              t("shop.noProduct")
              :


              shopping.shoppingData.orderLineList.map((x, y) => {
                return (
                  <ShoppingItem
                    key={y}
                    price={x.product.price}
                    name={x.product.name}
                    quantity={x.quantity}
                    img={x.product.productImageDtoList}
                    category={x.product.category}
                    city={x.product.city}
                    country={x.product.country}
                    state={x.product.state}
                    region={x.product.region}
                    forestType={x.product.forestType}
                    active={x.product.active}
                    stockCredits={x.product.stockCredits}
                    id={x.product.id}
                    provider={x.product.provider}
                    providerId={x.product.providerId}
                    handlerUpdate={handlerUpdate}
                    actualizar={actualizar}

                  />
                )
              })
            }


            <tr className='flex'>
              <th>
                <div className="font-semibold text-center td-imagen w-60 md:w-[16rem] lg:w-[24rem] xl:w-[28rem] 2xl:w-[30rem]"> </div>
              </th>
              <th>
                <div className="font-semibold text-center td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52">{t("shop.total")}:</div>
              </th>
              <th>
                <div className="font-semibold text-center td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52">{quantityTotal}</div>
              </th>
              <th>
                <div className="font-semibold text-center td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-[11.5rem] 2xl:w-52">{data?.total && formatThousands(data?.total)} € </div>
              </th>
              <th>
                <div className="font-semibold text-center td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-[11.5rem] 2xl:w-52"></div>
              </th>
            </tr>
          </tbody>
        </table>


      </div>
      <div className='mt-2 mx-2 mb-5'>

        {shopping.shoppingData.orderLineList === undefined || shopping.shoppingData.orderLineList === null ?
          ""
          :
          <ShoppingBannerSummary
            data={shopping.shoppingData.orderLineList}
            envQ={envQ}
            datos={data}

          />

        }
      </div>
    </div>
  )
}
