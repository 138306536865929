import React from 'react'
import Carousel from 'react-bootstrap/Carousel'



export default function CarouselMk(imagenProduct) {
    const imgCarousel = imagenProduct.imagenProduct.filter((x) => x.principal !== true && x.satelital===false)

    return (
        <Carousel className='carousel-img'>
            {imgCarousel.map((x, key) =>
                <Carousel.Item key={key} >
                    <img
                        className="d-block w-100"
                        src={x.path}
                        alt="First slide"

                    />
                    <Carousel.Caption>

                    </Carousel.Caption>
                </Carousel.Item>
            )}
        </Carousel>







    )


}
