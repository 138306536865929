import pinoresinero from '../images/pinoresinero.jpg';
import pinosilvestre from '../images/pinosilvestre .jpg';
import pinoradiata from '../images/pinoradiata.jpg';
import castaño from '../images/castaño.jpg';
import mixto from '../images/mixto.jpg';
import roble from '../images/roble.jpeg';
import haya from '../images/haya.jpeg';
import eucalipto from '../images/eucalipto.jpg';
import cedro from '../images/cedro.jpg';
import arbol from '../images/arbol.jpeg';
import encina from '../images/encina.webp';
import sabina from '../images/sabinaalbar.webp';
import quejico from '../images/quejigo.webp';
import robleAlbar from '../images/roblealbar.webp';
import pinoNegro from '../images/pinus-uncinata.webp';
import pinoLaricio from '../images/pinusnigra.webp';
import pinusCanario from '../images/pinus-canar.webp';
import melojo from '../images/melojo.webp';
import abetoDouglas from '../images/abetodouglas.webp';
import abetoComun from '../images/abeto.webp';

export const handleClick = () => {
  window.scrollTo(0, 0);
};

export function createSlug(name) {
  const tilde = {
    'á': 'a',
    'é': 'e',
    'í': 'i',
    'ó': 'o',
    'ú': 'u',
    'ü': 'u',
    'ñ': 'n'
  };
  const slug = name
    .toLowerCase()
    .replace(/[áéíóúüñ]/g, (match) => tilde[match] || match)
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s]+/g, '-')
    .replace(/^-+|-+$/g, '')
    ;

  return slug;
}


export function generateUrl(route, params) {
  const { name, id } = params;
  const slug = createSlug(name);
  const path = route.replace(':slug', slug).replace(':id', id);
  return path;
}


export function productUrl(v) {
  return generateUrl('/product/:slug/:id', { name: v.name, id: v.id });
}
export function providerUrl(v) {
  return generateUrl('/provider/:slug/:id', { name: v.provider, id: v.providerId })
}




const speciesImages = {
  pinorodenooresinero: pinoresinero,
  pinosilvestre: pinosilvestre,
  pinoradiata: pinoradiata,
  castaño: castaño,
  mixto: mixto,
  roble: roble,
  haya: haya,
  eucalipto: eucalipto,
  cedro: cedro,
  encina: encina,
  sabinaalbar: sabina,
  quejigo: quejico,
  roblealbar: robleAlbar,
  pinonegro: pinoNegro,
  pinolaricio: pinoLaricio,
  pinocanario: pinusCanario,
  melojo: melojo,
  abetodedouglas: abetoDouglas,
  abetocomun: abetoComun
};


export function speciesImage(species) {

  if (species in speciesImages) {
    return speciesImages[species];
  } else {
   
    return arbol;
  }
}

