import React from 'react'

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Carousel from 'better-react-carousel'
import CardComponent from './CardComponent'
import { useCategoriesContext } from '../context/categoriesContext';
import { useTranslation } from 'react-i18next';


export default function Featured() {

    const { t } = useTranslation();

    const categoriesContext = useCategoriesContext();

    // FETCH para categorias
    const [categories, setCategories] = useState({
        categoriesData: categoriesContext.categories,
        isFetching: false
    });


    const [featured, setFeatured] = useState({
        featuredData: [],
        isFetching: false
    });


    useEffect(() => {

        const fetchFeatured = async () => {
            try {
                setFeatured({ featuredData: featured.featuredData, isFetching: true });
                const response = await axios.get(`${process.env.REACT_APP_MP_FEATURED}`, {
                    // const response = await axios.get(`${process.env.REACT_APP_MP_FEATURED}`, {

                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        // 'Authorization': `Bearer ${auth.getToken()}`
                    }
                });
                setFeatured({ featuredData: response.data, isFetching: false });

            } catch (exception) {
                setFeatured({ featuredData: featured.featuredData, isFetching: false });
            }
        };
        fetchFeatured();
    }, []);





    const [width, setWidth] = useState(window.innerWidth);
    const [cols, setCols] = useState(4);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (width <= 1000) {
            setCols(1);
        } else if (width <= 1280) {
            setCols(2);
        }
        else if (width > 1280 && width < 1536) {
            setCols(3);
        }


        else if (width >= 1536) {
            setCols(4);
        }
    }, [width]);



    return (
        <div className='pb-12 pt-12' style={{  lineHeight: '100%', height: '100%' }}>
            <div className="max-w-3xl mx-auto text-center pb-3 md:pb-5">
                <h2 className="text-airco2-home-title mt-4 pb-2 md:pb-4">{t("home.ultimos")}</h2>
                <h3 className="text-airco2-home-subtitle">{t("home.ultimosCatalogo")}
                    <Link
                        className="mx-1 text-airco2-secondary"
                        to="/catalogo"
                    >
                        {t("home.catalogo")}
                    </Link></h3>
            </div>
            {featured.featuredData.length === 0 ?

                <div className=" w-12 h-12 spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status"></div>
                :
                <Carousel
                   
                    cols={cols} rows={1} gap={1} loop scrollSnap showDots
                    mobileBreakpoint={700}
                >

                    {featured.featuredData.map((v, y) => {
                        return (
                            <Carousel.Item key={y} >
                                <CardComponent
                                    styles=" card-container rounded-sm border border-principal justify-self-center"
                                    v={v}
                                    categories={categories} />
                            </Carousel.Item>
                        )
                    }
                    )}



                </Carousel>
            }



        </div>
    )
}
