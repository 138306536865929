import React from 'react';
import { useTranslation } from 'react-i18next';
import { Shopping } from '../components/Shopping';
import TemplatePage from './TemplatePage';



export default function Carrito() {
  const { t } = useTranslation();

  return (
    <div>
      <TemplatePage
        className={'container'}
        padding={'pt-28'}
        title={t("meta.shopping.title")}
        h1={t("meta.shopping.title")}

        >
      



        <Shopping />

      </TemplatePage>
    </div>
  )
}
