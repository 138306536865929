import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../context/authContext';
import { formatThousands } from '../../../utils/Utils';
import ListaPedidosLineItem from './ListaPedidosLineItem';
import { formatDateTime } from '../../../utils/Utils';

function ListaPedidosTableItem({ order }) {
    const { t } = useTranslation();
    const auth = useAuthContext();
    const [spinnerCertifacte,setSpinnerCertifacte] = useState(false);
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    const getUrlPayment = (orderId, addressId) => {

        const fetchPayment = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_MP_URL_PAYMENT}?orderId=${orderId}&addressId=${addressId}`,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': `Bearer ${auth.getToken()}`
                        }
                    })
                    .then((response) => {
                        window.location.href = response.data;

                    }, (error) => {
                        console.log(error);
                    });
            } catch (exception) {
                console.log(exception);
            }
        };
        fetchPayment();
    }


    const goToFinalized = () => {
        window.location.href = `/finishOrder/${order.id}`;
    }


    const statusColor = (status) => {
        switch (status) {
            case 'CART':
                return 'bg-zinc-100 text-zinc-600';
            case 'CONFIRMED':
                return 'bg-yellow-100 text-yellow-600';
            case 'WAITING_PAYMENT':
                return 'bg-orange-100 text-orange-600';
            case 'FINALIZED':
                return 'bg-green-100 text-green-600';
            default:
                return 'bg-zinc-100 text-zinc-600';
        }
    };


    const paymentStatusColor = (status) => {
        switch (status) {
            case 'NONE':
                return 'bg-zinc-100 text-zinc-600';
            case 'IN_PROGRESS':
                return 'bg-yellow-100 text-yellow-600';
            case 'ERROR':
                return 'bg-red-100 text-red-600';
            case 'SUCCESS':
                return 'bg-green-100 text-green-600';
            default:
                return 'bg-zinc-100 text-zinc-600';
        }
    };

    const getCompensationPdf = (order) => {
        setSpinnerCertifacte(true);
        let allForest= order.orderLineList.map(item => { return item.product.name });
        let allForestLocations= order.orderLineList.map(item => { return item.product.city });
        let allForestProviders= order.orderLineList.map(item => { return item.product.provider });

        let string = `?orderId=${order.id}&companyName=${auth.getCompanyName()}&totalVat=${order.totalVat}&creationDate=${formatDateTime(order.creationDate, 2)}&totalTons=${formatThousands(order.orderLineList.reduce((accum, item) => accum + item.quantity, 0))}&forest=${allForest.toString()}&provider=${allForestProviders.toString()}&location=${allForestLocations.toString()}`;
        const fetchCompensationPdf = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_MP_ORDER_NEUTRALIZATION_CERTIFICATE}${string}`,

                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': `Bearer ${auth.getToken()}`
                        }
                    })
                    .then((response) => {
                        setSpinnerCertifacte(false);

                        window.open(response.data, '_blank', 'noreferrer');

                    }, (error) => {
                        setSpinnerCertifacte(false);
                        console.log(error);
                    });
            } catch (exception) {
                console.log(exception);
            }
        };
        fetchCompensationPdf();

    }
    
    return (<tbody className="text-sm" key={"order" + order.id}>
        <tr>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  text-center">
                <div className="font-bold text-airco2-principal"> #{order.id}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center text-airco2-general">
                <div>{formatDateTime(order.creationDate)}</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-right text-airco2-general">
                <div>  {formatThousands(order.total)} €</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-right text-airco2-general">
                <div> {formatThousands(order.totalVat)}  €</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-right text-airco2-general">
                <div> {formatThousands(order.orderLineList.reduce((accum, item) => accum + item.quantity, 0))} t</div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center text-airco2-general">
                <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(order.orderStatus)}`}>
                    {t("account.orders." + order.orderStatus.toLowerCase())}
                </div>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-center text-airco2-general">
                <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${paymentStatusColor(order.paymentStatus)}`}>
                    {t("account.orders." + order.paymentStatus.toLowerCase())}
                </div>
            </td>

            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-px  text-center text-airco2-general">
                <button
                    className={`text-gray-400 hover:text-gray-500 transform ${descriptionOpen && 'rotate-180'}`}
                    aria-expanded={descriptionOpen}
                    onClick={() => setDescriptionOpen(!descriptionOpen)}
                    aria-controls={`description-${order.id}`}>
                    <span className="sr-only">Ver líneas</span>
                    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                        <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                    </svg>
                </button>
            </td>
            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap  w-px  text-center text-airco2-general">
                {(order.orderStatus === "WAITING_PAYMENT" && order.paymentStatus === "IN_PROGRESS" && order.address !== null) && <button onClick={() => getUrlPayment(order.id, order.address.id)}>{t("account.orders.tryPayment")}</button>}
                {(order.orderStatus === "CONFIRMED") && <button onClick={() => goToFinalized()}>{t("account.orders.finishOrder")}</button>}
                {order.paymentStatus === "SUCCESS" ?
                    <div className='inline-flex'>
                        {spinnerCertifacte ? 
                            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-airco2-secondary" role="status"/>
                            :
                            <button onClick={() => getCompensationPdf(order)} data-toggle="tooltip" data-placement="left" title="Certificado de Compensación">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-download" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#96d6c0" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                    <line x1="12" y1="11" x2="12" y2="17" />
                                    <polyline points="9 14 12 17 15 14" />
                                </svg>
                            </button>
                        }

                        {order.urlInvoiceStripe !== null && order.urlInvoiceStripe != "" &&
                            <a href={order.urlInvoiceStripe} target='_blank' data-toggle="tooltip" data-placement="left" title="Descargar factura">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-invoice" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dd4bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                    <line x1="9" y1="7" x2="10" y2="7" />
                                    <line x1="9" y1="13" x2="15" y2="13" />
                                    <line x1="13" y1="17" x2="15" y2="17" />
                                </svg></a>
                        }

                    </div>
                    : ""
                }

            </td>
        </tr>

        <tr id={`description-${order.id}`} role="region" className={`${!descriptionOpen && 'hidden'}`}>
            <td></td>
            <td colSpan="7" className="px-2 first:pl-5 last:pr-5 py-3">

                <table className="hover:table-auto w-full divide-y divide-gray-200 text-airco2-general">
                    <thead>
                        <tr>
                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center" colSpan="5">{t("account.orders.product")}</th>
                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.quantity")}</th>
                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.unitPrice")}</th>
                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.invoice")}</th>

                        </tr>
                    </thead>
                    {order.orderLineList.map(itemOrder => {
                        return <ListaPedidosLineItem itemOrder={itemOrder} key={"line" + itemOrder.id}></ListaPedidosLineItem>
                    })}

                </table>
            </td>
        </tr>
    </tbody>)
}
export default ListaPedidosTableItem;
