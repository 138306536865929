import React from 'react'
import iconDelete from '../images/deleteIcon.svg'
import { useAuthContext } from '../context/authContext'

export default function DeleteShopping({

  idProduct,
  right,
  width,
  clase,
  actualizar


}) {


  // funcion que manda al padre que borra
  const handlerUpdate = (v) => {
    actualizar(v)
  }

  const auth = useAuthContext();

  const handleDelete = () => {
    const data = {

      "idProduct": idProduct

    }
    fetch(`${process.env.REACT_APP_MP_SHOP_PRODUCT}`,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${auth.getToken()}`

        },
        body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((responseJson) => {
        handlerUpdate(responseJson)
        return responseJson;
      });


  }

  return (
    <button className={`${clase}`} style={{ right: `${right}` }}>
      <img onClick={handleDelete} src={iconDelete} width={`${width}`} alt='delete'></img>
    </button>
  )
}
