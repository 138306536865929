import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
// import * as serviceWorker from './serviceWorker'
import { AuthContextProvider } from './context/authContext';
import { CategoriesContextProvider } from './context/categoriesContext';
import { CountriesContextProvider } from './context/countriesContext';
import { ShoppingContextProvider } from './context/shoppingContext';
import './translations/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthContextProvider>
        <CategoriesContextProvider>
            <CountriesContextProvider>
                <ShoppingContextProvider>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </ShoppingContextProvider>
            </CountriesContextProvider>
        </CategoriesContextProvider>
    </AuthContextProvider>

);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
