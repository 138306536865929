import { useTranslation } from 'react-i18next';

function AddressItem({ address, setAddresToEdit }) {
    const { t } = useTranslation();



    return (<div className="basis-1/4 p-6 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
        <div className="flex">
            <h2 className="mb-2 text-2xl font-semibold tracking-tight text-airco2-general dark:text-white">{address.nameAddress}</h2>
            {address.principal &&
                <span className="ml-5 mt-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16"> <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" fill="#e6c542"></path> </svg></span>
            }
        </div>
        <p className="font-semibold text-lg text-airco2-general">{address.person}</p>

        <p className="font-normal text-airco2-general">{address.street}</p>
        <p className="font-normal text-airco2-general">{address.pc} {address.city}</p>
        <p className="font-normal text-airco2-general">{address.state} {address.country}</p>


        <div className="mt-3">
            <a href="#" className="inline-flex items-center text-airco2-principal hover:underline" onClick={() => setAddresToEdit(address)}>
                {t("account.address.edit")}        <svg className="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
            </a>
        </div>
    </div>)
}

export default AddressItem;