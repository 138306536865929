
import TableLastOrderItem from './TableLastOrderItem'
import { useTranslation } from 'react-i18next'

function TableLastOrder(props) {

    const { t } = useTranslation();


let orders = props.ordersid

    return (< div className="grow">

        {/* Panel body */}
        <div className="space-y-6">
          

            

            {(orders.length > 0) &&

                <section>
                    <div className="overflow-x-auto">

                        <table className="hover:table-auto w-full divide-y divide-gray-200">
                            <thead className="text-xs uppercase text-airco2-general border-t border-gray-200" style={{backgroundColor:'rgb(141, 212, 189,0.2)'}}>

                                <tr >
                                    {/* //TODO: Transa */}
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.comprador")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.date")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.toneladas")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.totalCompra")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.totalforestal")}</th>
                                    
                                    
                        
                                </tr>
                            </thead>

                            {orders.map(order => {
                                return (<TableLastOrderItem order={order} key={"order" + order.id}></TableLastOrderItem>)
                            })}

                        </table>
                    </div>


                </section>

            }

           

        </div>

    </div >
    );
}

export default TableLastOrder;
