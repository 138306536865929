import axios from 'axios'
import { useState, useEffect } from 'react'
import { useAuthContext } from '../../../context/authContext'
import ListaPedidosTableItem from './ListaPedidosTableItem';
import { useTranslation } from 'react-i18next'

function ListaPedidosTable() {
    const auth = useAuthContext();
    const { t } = useTranslation();

    const [orders, setOrders] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_MP_ORDERS}`, {

                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${auth.getToken()}`
                    }
                });
                setOrders({ data: response.data, isFetching: true });

            } catch (exception) {
                console.log(exception);
                setOrders({ data: orders.data, isFetching: true });
            }
        };
        fetchOrders();

    }, []);


    return (< div className="max-w-3xl md:max-w-lg lg:max-w-full">

        {/* Panel body */}
        <div className="p-6 space-y-6">
            <h2 className="text-2xl text-airco2-filter-content mb-5">{t("account.orders.myorders")}</h2>

            {!orders.isFetching && <div className="text-center">
                <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-principal" role="status">
                    <span className="visually-hidden">{t("account.orders.getOrders")}</span>
                </div>
                <div>
                    {t("account.orders.getOrders")}
                </div>
            </div>}

            {(orders.isFetching && orders.data.length > 0) &&

                <section>
                    <div className="overflow-x-auto">

                        <table className="hover:table-auto w-full divide-y divide-gray-200">
                            <thead className="text-xs uppercase text-airco2-general bg-gray-50 border-t border-gray-200">

                                <tr >
                                    {/* //TODO: Transa */}
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.order")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.date")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.total")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.totalVat")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.totalTons")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.orderStatus")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.paymentStatus")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.info")}</th>
                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">{t("account.orders.actions")}</th>
                                </tr>
                            </thead>

                            {orders.data.map(order => {
                                return (<ListaPedidosTableItem order={order} key={"order" + order.id}></ListaPedidosTableItem>)
                            })}

                        </table>
                    </div>


                </section>

            }

            {(orders.isFetching && orders.data.length === 0) && t("account.orders.noOrders")}

        </div>

    </div >
    );
}

export default ListaPedidosTable;
