import React, { useEffect, useState } from 'react';
import CardComponent from './CardComponent';
import { useCategoriesContext } from '../context/categoriesContext';

function CardCatalogo({
  products,
  currentpage
}) {

  const categoriesContext = useCategoriesContext();

  // FETCH para categorias
  const [categories, setCategories] = useState({
    categoriesData: categoriesContext.categories,
    isFetching: false
  });

  const productswId = products.map((element) => {
    if (element.id !== null) {
      return element;
    } return ''
  })
  const productsFinal = productswId.filter((element) => {
    return element !== undefined;
  })

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(products);
  }, [currentpage]);
  return (
    productsFinal.map((v, i) => {
      return (
        <CardComponent key={i}
          v={v} categories={categories}
          styles="flex col-md-1 card-container rounded-sm border border-principal"
        />
      )
    }
    ));
}

export default CardCatalogo;