import axios from "axios";
import { useAuthContext } from "./context/authContext";
import { useEffect } from "react";




export default function Sitemap() {
    const auth = useAuthContext();

    useEffect(() => {
        const fetchSitemap = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_MP_SITEMAP}`,
                ).then((response) => {
                    writeToTextFile(response)

                });

            } catch (exception) {
                console.error("Error al generar el sitemap")
            }
        };
        fetchSitemap();
    }, []);



    const writeToTextFile = (data) => {
        const content = JSON.stringify(data);

        const blob = new Blob([content], { type: 'text/xml' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'sitemap.xml'; // Nombre del archivo de texto que se descargará
        link.click();

        URL.revokeObjectURL(url);
    };




    return <>
    </>
}

