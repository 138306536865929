import axios from 'axios';
import React, { useState } from "react";
import { useAuthContext } from '../context/authContext';
import { useCategoriesContext } from '../context/categoriesContext';
import CardCarrito from './CardCarrito';
import DeleteShopping from './DeleteShopping';
import { formatThousands } from './../utils/Utils';

export default function ShoppingItem(

  props) {

  const auth = useAuthContext();


  //funcion que recibe si se borra o no del hijo
  let [envD, updateEnvD] = useState("");


  let itemProduct = props

  const actualizar = (v) => {
    updateEnvD(v)
    props.handlerUpdate(v)
  }


  const categoriesContext = useCategoriesContext();

  // FETCH para categorias
  const [categories, setCategories] = useState({
    categoriesData: categoriesContext.categories,
    isFetching: false
  });




  let [quantity, setQuantity] = useState(props.quantity)



  const handleChangeValue = async (e) => {
    const newValue = e.target.value;
    const data = {
      idProduct: props.id,
      quantity: newValue,
      price: props.price
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_MP_SHOP}`, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${auth.getToken()}`
        }
      });
      props.actualizar(response.data);
    } catch (error) {
      console.error(error);
    }
    setQuantity(newValue);
    // props.actualizar(response.data);
  };
  const reserved = itemProduct.reservedCredits === undefined ? 0 : itemProduct.reservedCredits;
  return (

    <tr className="flex">

      <td className="px-2 whitespace-nowrap td-imagen w-60 md:w-[16rem] lg:w-[24rem] xl:w-[28rem] 2xl:w-[30rem]" >



        <CardCarrito
          categories={categories}
          products={props}

        ></CardCarrito>

      </td>


      <td className="px-2 first:pl-5 last:pr-5 py-3 td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52  "  >
        <div className={`text-center`}>{formatThousands(itemProduct.price)} €</div>
      </td>


      <td className="px-2 first:pl-5 last:pr-5 py-3 td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52">
        <div className='flex flex-wrap gap-1'>
          <input defaultValue={itemProduct.quantity} className='input-change-value px-2 text-end border border-gray-300 ' style={{ minWidth: '3rem' }} type="number" id="numCredit" name="numCredit"
            min="1" max={`${(itemProduct.stockCredits - reserved)}`} onChange={handleChangeValue} />
          <span> /  {`${formatThousands((itemProduct.stockCredits || 0) - (reserved || 0))}`}</span> 

        </div>
      </td>


      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52 " >
        <div className="text-center">

          {`${formatThousands((itemProduct.price * quantity))}`} €
        </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap td-texto w-16 sm:w-24 md:w-32 lg:w-40 xl:w-44 2xl:w-52" >
        <div className="text-center">

          <DeleteShopping
            categories={categories}
            width={'30px'}
            idProduct={itemProduct.id}
            actualizar={actualizar}
          />
        </div>
      </td>


    </tr>

  );
}