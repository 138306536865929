import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../context/authContext';
import { useShoppingContext } from '../context/shoppingContext';
import arbol from '../images/arbolDetail.jpg';
import circle from '../images/location.svg';
import Transition from '../utils/Transition';
import { formatThousands } from '../utils/Utils';
import DeleteShopping from './DeleteShopping';
import owner from '../images/userProvider.svg'
import { useTranslation } from 'react-i18next';
import Contador from './contador';
import { handleClick } from './Utils';


function ModalCarrito({
  align
}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const auth = useAuthContext();
  const { t } = useTranslation();

  const shoppingContext = useShoppingContext();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });


  useEffect(() => {
    if (shoppingContext.updateCartState) {
      setShopping({ shoppingData: shoppingContext.cart, isFetching: true });
      if (window.location.pathname !== "/shopping" && width >= 639) {
        setDropdownOpen(true); //mirar aqui
      }
      shoppingContext.setUpdateCartState(false);
    }

  }, [shoppingContext.updateCartState]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });


  const [shopping, setShopping] = useState({
    shoppingData: [],
    isFetching: false,
    error: false,
  });

  useEffect(() => {
    const fetchShopping = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_MP_SHOP}`, {

          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${auth.getToken()}`
          }
        });
        shoppingContext.inicialize(response.data);
        setShopping({ shoppingData: response.data, isFetching: true });

      } catch (exception) {
        setShopping({ shoppingData: shopping.shoppingData, isFetching: true, error: true });
      }
    };

    if (!shoppingContext.isInicialize) {
      fetchShopping();
    } else {
      setShopping({ shoppingData: shoppingContext.cart, isFetching: true });
    }
  }, [shoppingContext.cart]);





  const dataProduct = shopping.shoppingData.orderLineList

  const dataOrder = dataProduct === undefined || dataProduct === null ? "" : dataProduct.sort(((a, b) => b.id - a.id))
  const idProduct = shopping.shoppingData.orderLineList === undefined || shopping.shoppingData.orderLineList === null ? "" :
    shopping.shoppingData.orderLineList.map((x) =>
      x.id)

  const idMax = Math.max(...idProduct)

  //funcion que recibe si se borra o no del hijo
  let [envD, updateEnvD] = useState("");

  let totalEuros = 0;
  let totalToneladas = 0;



  const handlerUpdate = (v) => {
    shoppingContext.updateCart(v);
    setShopping({ shoppingData: v, isFetching: true })
  }

  const actualizar = (v) => {
    updateEnvD(v)
    handlerUpdate(v)
  }

  const getTotal = (quantity, price) => {
    totalToneladas += quantity;
    totalEuros += price;
  }
  const expiredDate = shopping.shoppingData.expiredDate


  return (
    <div className="" >
      {((shopping.isFetching && shopping.shoppingData !== undefined && shopping.shoppingData.orderLineList !== null)
        || (shopping.isFetching && shopping.shoppingData !== null && shopping.shoppingData.orderLineList !== null)) &&
        <>
          <div className='relative'>
            <div className='hidden sm:block'>
              <div className="absolute text-xs font-semibold text-airco2-general px-1.5 bg-white rounded-full bottom-5 left-5">{shopping.shoppingData.orderLineList !== undefined ? shopping.shoppingData.orderLineList.length : ""}</div>
            </div>
            <button
              ref={trigger}
              className={` text-arico2-footerHeader hidden sm:block w-8 h-8 flex items-center justify-center transition duration-150 rounded-full rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 ${dropdownOpen}`}
              // className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ${dropdownOpen && 'bg-gray-200'}`}
              aria-haspopup="true"
              onClick={() => setDropdownOpen(!dropdownOpen)}
              aria-expanded={dropdownOpen}
            >
              <span className="sr-only">Compras</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart w-8 h-8" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="6" cy="19" r="2" />
                <circle cx="17" cy="19" r="2" />
                <path d="M17 17h-11v-14h-2" />
                <path d="M6 5l14 1l-1 7h-13" />
              </svg>
              {/* <img alt='iconoCarrito' src={shoppingCart}></img> */}

            </button>
          </div>
          {/* BOTON CARRITO EN FORMATO MVL */}
          <Link onClick={handleClick} to="/shopping">
            <div className='block sm:hidden'>
              <div className="absolute text-xs font-semibold text-airco2-general px-1.5 bg-white rounded-full top-6 ml-5">{shopping.shoppingData.orderLineList.length}</div>
            </div>
            <button

              className={` text-arico2-footerHeader block sm:hidden w-8 h-8 flex items-center justify-center transition duration-150 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600 ${dropdownOpen}`}>

              <span className="sr-only">Compras</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart w-8 h-8" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="6" cy="19" r="2" />
                <circle cx="17" cy="19" r="2" />
                <path d="M17 17h-11v-14h-2" />
                <path d="M6 5l14 1l-1 7h-13" />
              </svg>

            </button>
          </Link>
        </>

      }

      <Transition
        className={`origin-top-right z-10 absolute top-full -mr-48 sm:mr-0 min-w-80 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"

      >
        <div className='modal-carrito px-4 mb-3'
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="text-xs font-semibold text-airco2-general uppercase pt-1.5 pb-2 flex justify-between">

            <span>   {t("modalShop.compras")}</span>

            {(shopping.shoppingData.orderLineList === undefined || shopping.shoppingData.orderLineList === null || shopping.shoppingData.orderLineList.length === 0) ?
              ""
              :
              <div className='flex items-center '>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alarm" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#F19B7F" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="13" r="7" />
                    <polyline points="12 10 12 13 14 13" />
                    <line x1="7" y1="4" x2="4.25" y2="6" />
                    <line x1="17" y1="4" x2="19.75" y2="6" />
                  </svg>
                </span>


                <span className='text-sm mt-1'>
                  <Contador
                    expiredDate={expiredDate}

                  />
                </span>

              </div>


            }

          </div>
          <div className=' text-airco2-general'>

            {(!shopping.isFetching)
              && <div className='text-center text-xs text-airco2-general'>{t("cat.cargando")}</div>}


            {shopping.isFetching && shopping.shoppingData.length === 0 ?
              (shopping.error) ?
                <div className='text-center text-xs text-airco2-general'>{t("modalShop.loginCarrito")}.</div>
                :
                (shopping.isFetching && !shopping.error) ?
                  <div className='text-center text-xs text-airco2-general'>{t("modalShop.noProducto")}.</div>
                  : ""
              :
              (shopping.shoppingData.orderLineList === undefined || shopping.shoppingData.orderLineList === null || shopping.shoppingData.orderLineList.length === 0)
                ?
                <div className='text-center text-xs text-airco2-general'>{t("modalShop.noProducto")}.</div>
                :
                <>
                  {shopping.shoppingData.orderLineList.map((x, key) =>
                    <div key={key} className="border-b border-gray-200 last:border-0 ">
                      <div className='mt-2 mb-2 flex  flex-row  gap-3'>

                        <div className="basis-3/12 block text-sm text-airco2-general ">
                          {
                            x.product.productImageDtoList.length === 0 ?
                              <img alt='arbolcarrito' src={arbol} className='w-full h-full'></img>
                              :
                              x.product.productImageDtoList.map((y, index) =>
                                (y.principal) &&
                                <img key={index} alt='arbolcarrito' className='w-full h-full' src={y.path}></img>

                              )}
                        </div>
                        <div className='basis-8/12 '>
                          <div >
                            <div className=" flex font-bold text-airco2-general">
                              {/* <span></span>  🛒  */}
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <circle cx="6" cy="19" r="2" />
                                  <circle cx="17" cy="19" r="2" />
                                  <path d="M17 17h-11v-14h-2" />
                                  <path d="M6 5l14 1l-1 7h-13" />
                                </svg>
                              </span>
                              <span>{x.product.name}</span>
                              {(idMax !== undefined && idMax === x.id) &&

                                <span >
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-artboard" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#F19B7F" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <rect x="8" y="8" width="8" height="8" rx="1" />
                                    <line x1="3" y1="8" x2="4" y2="8" />
                                    <line x1="3" y1="16" x2="4" y2="16" />
                                    <line x1="8" y1="3" x2="8" y2="4" />
                                    <line x1="16" y1="3" x2="16" y2="4" />
                                    <line x1="20" y1="8" x2="21" y2="8" />
                                    <line x1="20" y1="16" x2="21" y2="16" />
                                    <line x1="8" y1="20" x2="8" y2="21" />
                                    <line x1="16" y1="20" x2="16" y2="21" />
                                  </svg>



                                </span>



                              }</div>

                          </div>
                          <div className='flex gap-1'>
                            <img alt='circulo-icono' src={circle} width={'14px'} ></img>
                            <div className="block text-xs">{x.product.state}, {x.product.city}</div>
                          </div>
                          <div className='flex gap-1'>
                            <img alt='circulo-icono' src={owner} width={'14px'} ></img>
                            <div className="block text-xs">{x.product.provider}</div>
                          </div>
                          <div className='flex justify-between justify-items-center italic mt-2 '>

                            <div className="text mb-2 text-sm"><span className='font-bold'>{x.quantity}</span> <span className='text-xs'>  {t("modalShop.tdeC")} </span> {formatThousands(x.unitPrice)} <span className='text-xs'>€/t</span></div>
                            <div className="text mb-2 font-bold text-sm">{formatThousands((x.unitPrice * x.quantity).toFixed(2))} €</div>
                            {getTotal(x.quantity, x.unitPrice * x.quantity)}
                          </div>
                          {/* <div className="flex justify-center text-xs">
                            <span><span className='font-bold pr-1'>{(x.product.stockCredits - x.product.reservedCredits)}t</span>
                            disponibles</span>
                          </div> */}
                        </div>

                        <div className='basis-1/12 place-self-center w-full text-center'>
                          <DeleteShopping
                            idProduct={x.product.id}
                            right={''}
                            width={'20px'}
                            clase={''}
                            actualizar={actualizar}

                          />
                        </div>
                      </div>

                    </div>


                  )}
                  <div className='flex  flex-row  gap-3 text-airco2-general mt-2'>
                    <div className='basis-3/12 text-right'>
                      <span className='text-xs'>{t("modalShop.total")}:</span>
                    </div>

                    <div className='basis-8/12 '>

                      <div className='flex justify-between justify-items-center italic '>

                        <div className="text mb-2 text-sm">{formatThousands((totalToneladas).toFixed(2))} t {t("cardBuy.de")} CO2 </div>
                        <div className="text mb-2 font-bold text-sm">{formatThousands((totalEuros).toFixed(2))} €</div>
                      </div>
                    </div>
                    <div className='basis-1/12 '></div>
                  </div>



                  <Link onClick={handleClick} className='text-white text-xs'
                    to="/shopping">
                    <button className='flex justify-center bg-airco2-principal w-full pt-1 pb-1 '>
                      {t("modalShop.terminarCompra")}
                    </button>

                  </Link>





                </>

            }

          </div>

        </div>
      </Transition>

    </div>
  )
}

export default ModalCarrito;