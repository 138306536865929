import React from 'react';



function AuthPages({ children, image, decoration }) {

    return (
        <main className="">

            <div className="relative md:flex">

                {/* Content */}
                <div className="md:w-1/2">
                    <div className="min-h-screen h-full flex flex-col after:flex-1">

                        {/* Header */}
                        <div className="flex-1">
                            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                              
                            </div>
                        </div>

                        {/* Contenido */}
                        <div className="max-w-sm mx-auto px-4 py-8">

                            {children}

                        </div>

                    </div>
                </div>

                {/* Image */}
                <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
                    <img className="object-cover object-center w-full h-full" src={image} width="760" height="1024" alt="Authentication" />
                    <img className="absolute top-1/4 left-0 transform -translate-x-1/2 hidden lg:block" src={decoration} width="100" height="150" alt="Authentication decoration" />
                </div>

            </div>

        </main>
    );
}

export default AuthPages;