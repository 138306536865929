import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import bluePin from '../images/pin-de-ubicacion.png';
import { useState } from 'react';
import Modal from "react-modal";
import { useTranslation } from 'react-i18next';

export default function MapLocation({
    position,
    name

}
) {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const icon = new L.Icon({
        iconUrl: bluePin,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
    });


    return (
        <div style={{position:'relative', zIndex:'0'}} className='flex flex-col h-full'>

            {(isModalOpen === false) &&
                <div className= 'flex flex-col h-full'>
                    <MapContainer
                        style={{ width: "", height: isModalOpen === false ? "30vh" : "100%" }}
                        center={position}
                        zoom={16}
                        scrollWheelZoom={true}
                    >
                        <TileLayer
                            url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}

                        />
                        <Marker position={position} icon={icon} ><Popup>{name}</Popup></Marker>
                    </MapContainer>
                    <button className='text-end' onClick={() => setIsModalOpen(true)}>{t(`mapa.abrir`)}</button>
                </div>

            }


            <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>



                <MapContainer
                    style={{ width: "", height: isModalOpen === false ? "30vh" : "100%" }}
                    center={position}
                    zoom={16}
                    scrollWheelZoom={true}


                >
                    <TileLayer
                        url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}

                    />
                    <Marker position={position} icon={icon} ><Popup>{name}</Popup></Marker>
                </MapContainer>

            </Modal>

        </div>
    )
}
