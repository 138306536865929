import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function PopUpForestal() {
    const [show, setShow] = useState(true);

    const { t } = useTranslation();

    const currentTime = new Date().getTime();
    const expirationTime = 60 * 60 * 24 * 1000;
    const expiration = currentTime + expirationTime;
    const param = 'forestal'
    const handleClose = () => {
        setShow(false);
        localStorage.setItem('closed', JSON.stringify({ data: 'closed', expiration: expiration }));
    }



    useEffect(() => {
        const closedPop = localStorage.getItem('closed');
        if (closedPop) {
            const parsedData = JSON.parse(closedPop);
            const { data, expiration } = parsedData;

            if (new Date().getTime() < expiration) {

                //   console.log(data);
            } else {
                localStorage.removeItem('closed');
                //   console.log('El dato ha expirado');
            }
        } else {

            // console.log('El dato no existe');
        }
        if (closedPop) {
            setShow(false);
        }
    }, []);
    return (

        <>
            {show && (
                <div className="fixed z-50 p-2 rounded shadow-lg flex-row  border" style={{ right: '1vw', bottom: '1vw', backgroundColor: 'white' }}>
                    <span className='flex-row text-center'>
                        <span className='flex justify-end'>
                            <button className=" text-airco2-general rounded  font-bold" onClick={() => handleClose()}>x</button>
                        </span>

                        <h2 className="text-md font-bold text-airco2-general">{t("home.tienesmonte")}</h2>

                    </span>
                    <span className=''>
                        <div className='world'>
                            <div className="flex flex-col items-center pt-4 px-3 pb-2 md:pt-5 md:pb-3 w-100 md:w-25">
                                <div className="relative">
                                    <a target={"_blank"} href={`https://www.airco2.earth/register?param=${param}`} >
                                        <button className='bg-airco2-principal px-5 py-1 '>
                                            {t("home.unete")}
                                        </button>
                                    </a>

                                </div>
                            </div>
                        </div>

                    </span>

                </div>

            )

            }

        </>


    )
}
